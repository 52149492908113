import tools from "../../../extends/tools";
import BaseData from "./baseData.js";
import apis from "../../common/js/api2";
// import { printSave, checkTplCode } from "../../common/js/api2";
// import { getPrint,getTplAuth} from "../common/js/api";
import XLSX from 'xlsx'
import { Base64 } from 'js-base64';
import Mytable from "./mytable";

export default {
    insertSimpleForm() {
        if (this.form_detail_checked.length <= 0) {
            this.$message({
                type: 'error',
                message: '请先选择商品或资金详情字段'
            })
            return;
        }
        let q = tools.copy(this.simpleForm);
        q.id = tools.getId(16, 16);
        q.icon = this.formType;
        q.boxObject = tools.copy(BaseData.widgetTemplate.boxObject);
        q.boxObject.height = 4 * q.rowHeight + 40;
        q.boxObject.width = this.form_detail_checked.length * q.colWidth;
        //outline
        q.outlineStyle = 'none';
        q.outlineOffset = 8;
        q.pageSize = this.simplePrintPageSize;
        q.emptyLine = this.printSimpleEmptyLine;
        q.outWidth = 0;
        q.tableStyle = {
            outline: 'none',
            outlineOffset: '8px',
            width: '100%',
            margin: 0,
            padding: 0,
            borderCollapse: 'collapse',
            borderSpacing: 0
        };
        //生成行数，并设置行高列宽
        let table = [
            [],
            []
        ];

        for (let i = 0; i < q.pageSize; i++) {
            table.push([]);
        }
        this.form_detail_checked.forEach((item, index) => {
            let obj = {
                //数据实际值,如果是静态数据，该值为-1
                value: -1,
                //数据类型，1静态，2动态
                isDynamic: '静态',
                // 前显值
                valueShow: this.getSimpleFieldLabel(item),
                source: '',
                select: false, //是否选中，合并单元格
                colspan: 1,
                rowspan: 1,
                borderTopStyle: 'solid',
                borderTopWidth: 1,
                borderRightStyle: 'solid',
                borderRightWidth: 1,
                borderBottomStyle: 'solid',
                borderBottomWidth: 1,
                borderLeftStyle: 'solid',
                borderLeftWidth: 1,
                //单元格问题
                colWidth: 100 / this.form_detail_checked.length,
                rowHeight: '8mm',
                tdUnit: '%',
                style: {}
            };

            obj.style = tools.copy(q.textObject);
            obj.style.width = 100 / this.form_detail_checked.length + '%';
            obj.style.height = '8mm';
            obj.style.padding = '0px';
            obj.style.borderTop = '1px solid #000';
            obj.style.borderBottom = '1px solid #000';
            obj.style.borderLeft = '1px solid #000';
            obj.style.borderRight = '1px solid #000';
            obj.style.paddingLeft = '3px';
            // 第一行数据
            table[0][index] = obj;

            for (let i = 0; i < q.pageSize; i++) {
                // 第二行省略
                let obj2 = tools.copy(obj);
                obj.style.textAlign = "center";
                obj2.valueShow = '...';
                obj2.colspan = this.form_detail_checked.length;
                table[i + 1][0] = obj2;
            }
            // 第三行，统计
            let lastIndex = q.pageSize + 1;
            if (index === 0) {
                let obj1 = tools.copy(obj);
                obj1.valueShow = '汇总';
                table[lastIndex][0] = obj1;
            } else {
                let obj1 = tools.copy(obj);
                obj1.valueShow = '';
                table[lastIndex][index] = obj1;
                // 特殊处理
                if (item === 'goods_info.num') {
                    let obj1 = tools.copy(obj);
                    obj1.valueShow = '$$数量总和$$';
                    table[lastIndex][index] = obj1;
                }
                if (item === 'goods_info.actual') {
                    let obj1 = tools.copy(obj);
                    obj1.valueShow = '$$实际库存总和$$';
                    table[lastIndex][index] = obj1;
                }
                if (item === 'goods_info.breakeven_num') {
                    let obj1 = tools.copy(obj);
                    obj1.valueShow = '$$实际盈亏总和$$';
                    table[lastIndex][index] = obj1;
                }
                if (item === 'goods_info.breakeven_price') {
                    let obj1 = tools.copy(obj);
                    obj1.valueShow = '$$盈亏总额$$';
                    table[lastIndex][index] = obj1;
                }
            }
        });
        //
        q.rows = table;
        q.myClassName = 'table' + q.id;
        this.dragbox.push(q);

        this.setBoxActivate(q.id);
    },


    changeDrag(item) {
        if (this.currentBox.isDrag) {
            // this.currentBox.boxReverse = {};
            // this.currentBox.boxReverse.top = this.currentBox.boxObject.top + 'px';
            // this.currentBox.boxReverse.left = this.currentBox.boxObject.left + 'px';
            // this.currentBox.boxReverse.position = 'absolute';
            // this.currentBox.boxReverse.marginLeft = '0';
            // this.currentBox.boxReverse.marginTop = '0';
        } else {
            // delete this.currentBox.boxReverse.position;
            // this.currentBox.boxReverse.marginTop = this.currentBox.boxObject.top + 'px';
            // this.currentBox.boxReverse.marginLeft = this.currentBox.boxObject.left + 'px';
            // this.currentBox.boxReverse.top = 0;
            // this.currentBox.boxReverse.left = 0;
        }

        item.isDrag = !item.isDrag;
    },
    dragboxStart() {
        if (this.currentBox.type === 'table' && !this.currentBox.isDrag) {
            this.currentBox.boxObject.top = 0;
            this.currentBox.boxObject.left = 0;
        }
    },
    dragboxing() {
        if (this.currentBox.type === 'table' && !this.currentBox.isDrag) {
            this.currentBox.boxObject.top = 0;
            this.currentBox.boxObject.left = 0;
        }
    },
    tableListen() {
        //初始化表格控件
        if (this.currentBox.type === 'table') {
            this.currentBox.rows.forEach((i, k) => {
                i.forEach((ii, kk) => {
                    if (k === 0) {
                        //转换单元格宽高
                        delete ii.width;
                        delete ii.style.width;
                    }
                    if (kk === 0) {
                        delete ii.height;
                        delete ii.style.height;
                    }
                })
            });
        } else {
            this.currentBox.rows.forEach((i, k) => {
                i.forEach((ii, kk) => {
                    //转换单元格宽高
                    delete ii.width;
                    delete ii.style.width;
                })
            });
        }
        // tableReal.style.width = (this.currentBox.boxObject.width - 20) + 'px';
        // tableReal.style.height = (this.currentBox.boxObject.height - 20) + 'px';
        //
        if (this.tableCollect[this.currentBox.id] === undefined) {
            this.tableCollect[this.currentBox.id] = new Mytable.MyTable(this.currentBox, this);
            this.tableCollect[this.currentBox.id].init();
            this.changeDrag(this.currentBox);
        }
    },
    async init() {

        window.onload = function() {
            //禁用退格键返回功能
            document.onkeydown = function(e) {
                if (e.keyCode == 8) {
                    console.log('禁止回退')

                    // 如果是在textarea内不执行任何操作
                    var obj = e.target;
                    var tag = obj.tagName.toLowerCase();
                    if (tag != "input" && tag != "textarea" && tag != "password")
                        return false;
                }
            }
        }

        //获取用户类型，展示不同的模版
        let userinfo = JSON.parse(localStorage.getItem('userinfo'));
        this.isErp = userinfo.register_role_id == 1 ? 1 : 2;

        //获取模版类型
        await apis.typeList().then((res) => {
            //转换数据类型
            if (tools.msg(res, this)) {
                let danju = res.data;
                //单据二次加工，将左侧图标信息和右侧的工具信息包含进去
                danju.forEach((item, key) => {
                    item.icon = 'icon-huowu';
                    item.api_url = 'getFormSource' + item.id;
                    item.active = 0;
                    item.text = item.name;
                });

                this.danju_list = danju;
            }
        }).catch((err) => {
            tools.err(err, this);
        });

        //判断用户是否有创建连续模版的权限
        await apis.getTplAuth().then((res) => {
            if (res.code == 200) {
                this.loadPrintAuth = true;
                this.singlePrint = res.data[0].allow == 1;
                this.mutiplePrint = res.data[1].allow == 1;
            } else {
                this.$message({
                    type: 'error',
                    message: res.msg
                });
            }
        }).catch((err) => {
            tools.err(err, this);
        });
        //获取路由参数
        let id = this.$route.query.id;
        if ((!id && this.dragbox.length > 0) || (!id && this.templateType > 0)) {
            location.reload();
        }
        if (!!id) {
            const loading = this.$loading({
                lock: true,
                text: '获取模版数据中',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            //关闭excel选择窗口
            this.selectDialog = false;

            await apis.getPrint({ id: id }).then((res) => {
                this.textSource = JSON.parse(res.data.text_source);
                this.dragbox = JSON.parse(res.data.dragbox);
                //
                this.paper = JSON.parse(res.data.paper);
                this.tplId = res.data.id;
                this.templateName = res.data.names;
                this.templateType = res.data.text_class;
                this.formType = res.data.type;
                // 根据模版类型获取数据源
                if (this.formType !== 0) {
                    this.getTextSource();
                    if (this.formType === 3) {
                        // 获取快递公司类型
                        this.currentExpress = res.data.logistics_companies_id;
                        apis.expressList().then((res) => {
                            tools.msg(res, this) && (this.expressList = res.data);
                        }).catch((err) => {
                            console.log(err);
                        });
                    }
                }

                if (this.danju_list !== []) {
                    this.checkTypeItem();
                    setTimeout(() => {
                        loading.close();
                    }, 100);
                }


            }).catch((err) => {
                tools.err(err, this);
            });
            //获取模版信息，进行编辑
            // this.dragbox = JSON.parse(json);
        }

        //初始化,请求编辑器基础信息
        // editorInfo().then((res)=>{
        //     this.textSource = res;
        // });
        //创建模版数据
        this.tmp.text = tools.copy(this.text);
        this.tmp.image = tools.copy(this.image);
        this.tmp.qr = tools.copy(this.qr);
        this.tmp.barCode = tools.copy(this.barCode);
        this.tmp.table = tools.copy(this.table);
        this.tmp.lineBox = tools.copy(this.lineBox);
    },
    // 修改当前单据的分页数据，并且更改样式
    changePrintPageSize() {
        this.currentBox.pageSize = this.printPageSize;
        let rows = this.currentBox.rows;
        let startRow = tools.copy(rows[0]);
        let endRow = tools.copy(rows[rows.length - 1]);
        this.currentBox.rows = [];
        this.currentBox.rows.push(startRow);
        //
        for (let i = 0; i < this.currentBox.pageSize; i++) {
            this.currentBox.rows.push([]);
        }
        for (let i = 0; i < this.currentBox.pageSize; i++) {
            // 第二行省略
            let obj2 = tools.copy(startRow[0]);
            // 第二行省略
            obj2.style.textAlign = "center";
            obj2.valueShow = '...';
            obj2.colspan = this.currentShipMentField.length;
            this.currentBox.rows[i + 1][0] = obj2;
        }
        //
        this.currentBox.rows.push(endRow);
    },
    // 修改当前单据是否打印空行
    changePrintEmptyLine() {
        this.currentBox.emptyLine = this.printEmptyLine;
    },
    changePrintBorderLine() {
        this.currentBox.borderLine = this.borderLine;
    },
    changeTableBorder() {
        if (this.currentBox.type != 'table') {
            this.$message({
                type: 'error',
                message: '选中的控件不是表格，无法进行此操作'
            })
            return;
        }

        this.currentBox.borderLine = this.showTableBorder;
        //清除边框
        this.currentBox.rows.forEach(item => {
            // item.
            if (!this.currentBox.borderLine) {
                item.forEach(i => {
                    i.borderBottomStyle = 'none'
                    i.borderBottomWidth = 0
                    i.borderBottomStyle = "none"
                    i.borderBottomWidth = 0
                    i.borderLeftStyle = "none"
                    i.borderLeftWidth = 0
                    i.borderRightStyle = "none"
                    i.borderRightWidth = 0
                    i.borderTopStyle = "none"
                    i.borderTopWidth = 0
                    i.style.borderTop = 'none';
                    i.style.borderBottom = 'none';
                    i.style.borderLeft = 'none';
                    i.style.borderRight = 'none';
                });
            } else {
                item.forEach(i => {
                    i.borderBottomStyle = 'solid';
                    i.borderBottomWidth = 1
                    i.borderBottomStyle = 'solid';
                    i.borderBottomWidth = 1
                    i.borderLeftStyle = 'solid';
                    i.borderLeftWidth = 1
                    i.borderRightStyle = 'solid';
                    i.borderRightWidth = 1
                    i.borderTopStyle = "none"
                    i.borderTopWidth = 1;
                    i.style.borderTop = '1px solid #000';
                    i.style.borderBottom = '1px solid #000';
                    i.style.borderLeft = '1px solid #000';
                    i.style.borderRight = '1px solid #000';
                });
            }

        });

    },
    // 插入备货单
    insertPrepare() {
        let q = tools.copy(this.prepare);
        q.id = tools.getId(16, 16);
        q.icon = this.formType;
        q.boxObject = tools.copy(BaseData.widgetTemplate.boxObject);
        q.boxObject.height = 4 * q.rowHeight + 40;
        q.boxObject.width = this.currentPrepareField.length * q.colWidth;
        q.pageSize = this.printPageSize;
        q.emptyLine = this.printEmptyLine;
        //outline
        q.outlineStyle = 'none';
        q.outlineOffset = 8;
        q.outWidth = 0;
        q.tableStyle = {
            outline: 'none',
            outlineOffset: '8px',
            width: '100%',
            margin: 0,
            padding: 0,
            borderCollapse: 'collapse',
            borderSpacing: 0
        };
        //生成行数，并设置行高列宽
        let table = [
            [],
            []
        ];
        for (let i = 0; i < q.pageSize; i++) {
            table.push([]);
        }

        this.currentPrepareField.forEach((item, index) => {
            let obj = {
                //数据实际值,如果是静态数据，该值为-1
                value: -1,
                //数据类型，1静态，2动态
                isDynamic: '静态',
                // 前显值
                valueShow: this.getFieldLabelPrepare(item),
                source: '',
                select: false, //是否选中，合并单元格
                colspan: 1,
                rowspan: 1,
                borderTopStyle: 'solid',
                borderTopWidth: 1,
                borderRightStyle: 'solid',
                borderRightWidth: 1,
                borderBottomStyle: 'solid',
                borderBottomWidth: 1,
                borderLeftStyle: 'solid',
                borderLeftWidth: 1,
                //单元格问题
                colWidth: 100 / this.currentPrepareField.length,
                rowHeight: '10mm',
                tdUnit: '%'
            };
            obj.style = tools.copy(q.textObject);
            obj.style.width = 100 / this.currentPrepareField.length + '%';
            obj.style.height = '8mm';
            obj.style.padding = '0px';
            obj.style.borderTop = '1px solid #000';
            obj.style.borderBottom = '1px solid #000';
            obj.style.borderLeft = '1px solid #000';
            obj.style.borderRight = '1px solid #000';
            obj.style.paddingLeft = '3px';

            // 第一行数据
            table[0][index] = obj;
            // 第二行省略
            let obj2 = tools.copy(obj);
            obj.style.textAlign = "center";
            obj2.valueShow = '...';
            obj2.colspan = this.currentPrepareField.length;
            table[1][0] = obj2;

            for (let i = 0; i < q.pageSize; i++) {
                // 第二行省略
                let obj2 = tools.copy(obj);
                obj.style.textAlign = "center";
                obj2.valueShow = '...';
                obj2.colspan = this.currentShipMentField.length;
                table[i + 1][0] = obj2;
            }

            // 第三行，统计
            let lastIndex = q.pageSize + 1;
            // 第三行，统计
            if (index === 0) {
                let obj1 = tools.copy(obj);
                obj1.valueShow = '汇总';
                table[lastIndex][0] = obj1;
            } else {
                let obj1 = tools.copy(obj);
                obj1.valueShow = '';
                table[lastIndex][index] = obj1;
                // 特殊处理
                if (item === 'num') {
                    let obj1 = tools.copy(obj);
                    obj1.valueShow = '$$数量总和$$';
                    table[lastIndex][index] = obj1;
                }
            }
        });
        //
        q.myClassName = 'table' + q.id;
        q.rows = table;
        this.dragbox.push(q);
        // this.setBoxActivate(q.id);
    },
    // 创建发货单
    insertShipment() {
        let q = tools.copy(this.shipment);
        q.id = tools.getId(16, 16);
        q.icon = this.formType;
        q.boxObject = tools.copy(BaseData.widgetTemplate.boxObject);
        q.boxObject.height = 4 * q.rowHeight + 40;
        q.boxObject.width = this.currentShipMentField.length * q.colWidth;
        //outline
        q.outlineStyle = 'none';
        q.outlineOffset = 8;
        q.pageSize = this.printPageSize;
        q.emptyLine = this.printEmptyLine;
        q.outWidth = 0;
        q.tableStyle = {
            outline: 'none',
            outlineOffset: '8px',
            width: '100%',
            margin: 0,
            padding: 0,
            borderCollapse: 'collapse',
            borderSpacing: 0
        };
        //生成行数，并设置行高列宽
        let table = [
            [],
            []
        ];

        for (let i = 0; i < q.pageSize; i++) {
            table.push([]);
        }
        this.currentShipMentField.forEach((item, index) => {
            let obj = {
                //数据实际值,如果是静态数据，该值为-1
                value: -1,
                //数据类型，1静态，2动态
                isDynamic: '静态',
                // 前显值
                valueShow: this.getFieldLabel(item),
                source: '',
                select: false, //是否选中，合并单元格
                colspan: 1,
                rowspan: 1,
                borderTopStyle: 'solid',
                borderTopWidth: 1,
                borderRightStyle: 'solid',
                borderRightWidth: 1,
                borderBottomStyle: 'solid',
                borderBottomWidth: 1,
                borderLeftStyle: 'solid',
                borderLeftWidth: 1,
                //单元格问题
                colWidth: 100 / this.currentShipMentField.length,
                rowHeight: '8mm',
                tdUnit: '%'
            };

            obj.style = tools.copy(q.textObject);
            console.log('textobject', obj.style);
            // debugger;

            obj.style.width = 100 / this.currentShipMentField.length + '%';
            obj.style.height = '8mm';
            obj.style.padding = '0px';
            obj.style.borderTop = '1px solid #000';
            obj.style.borderBottom = '1px solid #000';
            obj.style.borderLeft = '1px solid #000';
            obj.style.borderRight = '1px solid #000';
            obj.style.paddingLeft = '3px';
            // 第一行数据
            table[0][index] = obj;

            for (let i = 0; i < q.pageSize; i++) {
                // 第二行省略
                let obj2 = tools.copy(obj);
                obj.style.textAlign = "center";
                obj2.valueShow = '...';
                obj2.colspan = this.currentShipMentField.length;
                table[i + 1][0] = obj2;
            }
            // 第三行，统计
            let lastIndex = q.pageSize + 1;
            if (index === 0) {
                let obj1 = tools.copy(obj);
                obj1.valueShow = '汇总';
                table[lastIndex][0] = obj1;
            } else {
                let obj1 = tools.copy(obj);
                obj1.valueShow = '';
                table[lastIndex][index] = obj1;
                // 特殊处理
                if (item === 'num') {
                    let obj1 = tools.copy(obj);
                    obj1.valueShow = '$$数量总和$$';
                    table[lastIndex][index] = obj1;
                }
                if (item === 'price') {
                    let obj1 = tools.copy(obj);
                    obj1.valueShow = '$$总价$$';
                    table[lastIndex][index] = obj1;
                }
                if (item === 'payment') {
                    let obj1 = tools.copy(obj);
                    obj1.valueShow = '$$实付总价$$';
                    table[lastIndex][index] = obj1;
                }
                if (item === 'total_fee') {
                    let obj1 = tools.copy(obj);
                    obj1.valueShow = '$$商品总价$$';
                    table[lastIndex][index] = obj1;
                }
            }
        });
        //
        q.rows = table;
        q.myClassName = 'table' + q.id;
        this.dragbox.push(q);

        this.setBoxActivate(q.id);
    },

    // 获取通用表单数据字段
    getSimpleFieldLabel(val) {
        let v = '';
        this.form_detail_source.forEach((item, index) => {
            if (item.value === val) {
                v = item.label;
            }
        });
        return v;
    },

    // 获取订单数据字段
    getFieldLabel(val) {
        let v = '';
        this.shipMentField.forEach((item, index) => {
            if (item.value === val) {
                v = item.label;
            }
        });
        return v;
    },
    // 获取配货单数据字段
    getFieldLabelPrepare(val) {
        let v = '';
        this.prepareField.forEach((item, index) => {
            if (item.value === val) {
                v = item.label;
            }
        });
        return v;
    },

    changeShipmentField() {
        console.log(this.currentShipMentField);

    },
    // 获取数据源
    getTextSource() {
        // 初始化发货单数据
        this.shipMentField = [{
            label: '序号',
            value: 'number'
        }];
        // 备货单
        this.prepareField = [{
            label: '序号',
            value: 'number'
        }];
        /**
         * 其他单据信息
         */
        // 0: {id: 1, name: "发货单"}
        // 1: {id: 2, name: "备货单"}
        // 2: {id: 3, name: "普通快递单"}
        // 3: {id: 4, name: "采购单"}
        // 4: {id: 5, name: "采购退货单"}
        // 5: {id: 6, name: "销售单"}
        // 6: {id: 7, name: "销售退货单"}
        // 7: {id: 8, name: "盘点"}
        // 8: {id: 9, name: "调拨"}
        // 9: {id: 10, name: "借出"}
        // 10: {id: 11, name: "借入"}
        // 11: {id: 12, name: "收款单"}
        // 12: {id: 13, name: "付款单"}
        // 采购单，采购退货单，销售单，销售退货单，盘点，调拨，借出，借入与商品相关，这类单据有相同的结构，分别是每页的
        // 通用字段，包括单据编号，调出仓库，被包含在infos中
        let form_info_source = [];

        // 还有关于商品的聚合字段goods
        // 收款单，付款单则是与商品无关，有单据通用信息，infos和 收付款记录组成
        let form_detail_source = [];


        // 订单获取动态字段
        //获取对应的数据源字段，备货单和快递单，发货单不一样,
        apis.getDynamicField({
            type: this.formType
        }).then((res) => {
            let arr = [];
            if (tools.msg(res)) {
                // 备货单
                if (this.formType === 2) {
                    for (let index in res.data) {
                        let newItem = {
                            label: res.data[index],
                            value: index
                        };
                        arr.push(newItem);

                        this.prepareField.push(newItem);
                    }
                }
                // 发货单
                else if (this.formType === 1) {
                    for (let index in res.data) {
                        let newItem = {
                            value: index,
                            children: []
                        };

                        if (index === 'list_info') {
                            newItem.label = '商品信息';
                        }
                        if (index === 'order_info') {
                            newItem.label = '订单信息';
                        }
                        if (index === 'receiver_info') {
                            newItem.label = '收货人信息';
                        }
                        if (index === 'waybill_info') {
                            newItem.label = '发货人信息';
                        }
                        if (index === 'member_info') {
                            newItem.label = '会员信息';
                        }
                        for (let i in res.data[index]) {
                            let obj = {
                                label: res.data[index][i],
                                value: i
                            };
                            // 单独处理发货单字段
                            if (index === 'list_info') {
                                this.shipMentField.push(obj);
                            }
                            newItem.children.push(obj);
                        }

                        arr.push(newItem);
                    }

                }
                // 0: {id: 1, name: "发货单"}
                // 1: {id: 2, name: "备货单"}
                // 2: {id: 3, name: "普通快递单"}
                // 3: {id: 4, name: "采购单"}
                // 4: {id: 5, name: "采购退货单"}
                // 5: {id: 6, name: "销售单"}
                // 6: {id: 7, name: "销售退货单"}
                // 7: {id: 8, name: "盘点"}
                // 8: {id: 9, name: "调拨"}
                // 9: {id: 10, name: "借出"}
                // 10: {id: 11, name: "借入"}
                // 11: {id: 12, name: "收款单"}
                // 12: {id: 13, name: "付款单"}
                //采购单
                else {
                    // 表单通用信息
                    for (let index in res.data.infos) {
                        let newItem = {
                            label: res.data.infos[index],
                            value: index
                        };
                        arr.push(newItem);
                    }

                    //判断是否存在商品信息
                    if (res.data.goods) {
                        let arr = [];
                        for (let index in res.data.goods) {
                            arr.push({
                                label: res.data.goods[index],
                                value: index
                            });
                            this.form_detail_source = arr;
                        }
                    }
                    // 判断是否存在资金信息
                    if (res.data.details) {
                        let arr = [];
                        for (let index in res.data.details) {
                            arr.push({
                                label: res.data.details[index],
                                value: index
                            });
                            this.form_detail_source = arr;
                        }
                    }

                    console.log(this.form_detail_source);
                }
                // 生成一些系统字段
                let sys = {
                    label: '系统信息',
                    value: 'systemInfo',
                    children: [{
                        label: '第 - 页',
                        value: 'currentPage'
                    }, {
                        label: '单据打印时间',
                        value: 'currentDatetime'
                    }]
                };

                arr.push(sys);
            }
            console.log(this.shipMentField);
            this.textSource = arr;
        }).catch((error) => {
            tools.err(error, this);
        });
    },

    // 插入线条
    insertLineBox() {
        let q = tools.copy(this.lineBox);
        q.id = tools.getId(16, 16);
        //插入线条类型处理2021.05.14
        this.dragbox.push(q);
        this.setBoxActivate(q.id);
    },
    /**
     * 图片操作
     */
    //插入文本框
    insertTextbox() {
        var box = tools.copy(this.tmp.text);
        box.id = tools.getId(16, 16);
        box.text = this.text.text;
        box.source = this.text.source;
        box.sourceShow = this.getSouceText(this.text.source);
        this.dragbox.push(box);
        this.setBoxActivate(box.id);
    },
    //改变数据源值
    getSouceText(source) {
        let sourceText = '';
        this.textSource.forEach((item, key) => {
            if (item.value === source) {
                sourceText = item.label;
                return;
            }

            !!item.children && item.children.forEach((i, k) => {
                if (i.value === source) {
                    sourceText = i.label;
                    return;
                }
            });
        });

        return '$$' + sourceText + '$$';
    },
    //插入图片
    pickPicture(file) {
        let img = tools.copy(this.tmp.image);
        img.id = tools.getId(16, 16);
        img.url = BaseData.baseUrl + file.response.font;
        this.dragbox.push(img);
        this.setBoxActivate(box.id);
    },
    handleError(err) {
        this.$alert('文件上传失败，请稍候重试', '错误警告', {
            confirmButtonText: '确定',
            callback: action => {
                // location.reload();
            }
        });
    },
    // 设置背景
    setBg() {
        this.paper.bg = this.image.url;
        this.removeBox(this.image.id);
    },
    rmBg() {
        this.paper.bg = '';
    },
    async printTip() {
        if (!this.mutiplePrint) {
            this.$prompt('如果您还没有邀请码，请咨询客服获得', '温馨提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消'
            }).then(({ value }) => {
                apis.checkTplCode({
                    code: value,
                    headers: tools.getToken()
                }).then((res) => {

                    if (res.code != 200) {
                        this.$message({
                            type: 'error',
                            message: res.msg
                        });
                        return;
                    }
                    this.mutiplePrint = true;
                    this.$message({
                        type: 'success',
                        message: '验证成功！'
                    });
                });
            }).catch((err) => {
                console.log(err);
                this.$message({
                    type: 'info',
                    message: '取消输入'
                });
            });
        }
    },
    /**
     * 二维码操作
     */
    // 插入动态二维码
    insertDynamic(file) {
        let q = tools.copy(this.tmp.qr);
        q.id = tools.getId(16, 16);
        if (!!file) {
            q.url = BaseData.baseUrl + file.response.font;
        }
        this.dragbox.push(q);
        this.setBoxActivate(q.id);
    },
    insertStaticBarCode() {
        let q = tools.copy(this.barCode);
        q.url = require('../../../assets/img/home/static_barcode.png');
        q.id = tools.getId(16, 16);
        q.staticText = '';

        this.dragbox.push(q);
        this.setBoxActivate(q.id);
    },
    insertStaticQr() {
        // if (this.qr.staticText == '') {
        //     this.$alert('请先填写二维码数据');
        //     return;
        // }
        let q = tools.copy(this.qr);
        q.id = tools.getId(16, 16);
        q.isDynamic = false;
        q.url = require('../../../assets/img/home/qrstatic.png');

        this.dragbox.push(q);
        this.setBoxActivate(q.id);
    },
    // 插入条形码
    insertbarCode(file) {
        let q = tools.copy(this.barCode);
        q.id = tools.getId(16, 16);
        if (!!file) {
            q.url = BaseData.baseUrl + file.response.font;
        }
        this.dragbox.push(q);
        this.setBoxActivate(q.id);
    },
    // 插入表格
    insertTable() {
        let q = tools.copy(this.table);
        q.id = tools.getId(16, 16);
        q.select_checked = false; //单元格被选中
        q.boxObject = tools.copy(BaseData.widgetTemplate.boxObject);
        //设置表格和控件的初始化高宽
        q.boxObject.height = q.rowNum * q.rowHeight;
        q.boxObject.width = q.colNum * q.colWidth;
        //outline
        q.outlineStyle = 'none';
        q.outlineOffset = 8;
        q.outWidth = 0;
        q.tableStyle = {
            outline: 'none',
            outlineOffset: '8px',
            width: '98%',
            height: '98%',
            // width: q.boxObject.width + 'px',
            // height: q.boxObject.height + 'px',
            margin: 0,
            padding: 0,
            borderCollapse: 'collapse',
            borderSpacing: 0
        };
        //生成行数，并设置行高列宽
        let table = [];
        for (let i = 0; i < q.rowNum; i++) {
            table.push([]);
            for (let j = 0; j < q.colNum; j++) {
                let obj = {
                    //数据实际值,如果是静态数据，该值为-1
                    value: -1,
                    //数据类型，1静态，2动态
                    isDynamic: '静态',
                    // 前显值
                    valueShow: '',
                    source: '',
                    select: false, //是否选中，合并单元格
                    colspan: 1,
                    rowspan: 1,
                    borderTopStyle: 'solid',
                    borderTopWidth: 1,
                    borderRightStyle: 'solid',
                    borderRightWidth: 1,
                    borderBottomStyle: 'solid',
                    borderBottomWidth: 1,
                    borderLeftStyle: 'solid',
                    borderLeftWidth: 1,
                    //单元格问题
                    colWidth: 100,
                    rowHeight: 50,
                    tdUnit: '%'
                };
                obj.style = tools.copy(q.textObject);
                // obj.style.width = q.colWidth + 'px';
                // obj.style.height = q.rowHeight + 'px';
                obj.style.padding = '0px';
                obj.style.borderTop = '1px solid #000';
                obj.style.borderBottom = '1px solid #000';
                obj.style.borderLeft = '1px solid #000';
                obj.style.borderRight = '1px solid #000';
                obj.style.paddingLeft = '3px';

                table[i][j] = obj;
            }
        }
        q.myClassName = 'table' + q.id;
        q.rows = table;
        this.dragbox.push(q);

        this.topTabActive = '单元格';
        this.setBoxActivate(q.id);
    },
    // 合并单元格
    selectTd($e, rows, cols) {
        this.table.rows[rows][cols].select = !this.table.rows[rows][cols].select;
        if (this.table.rows[rows][cols].select) {
            $e.target.style.background = 'red';
        } else {
            $e.target.style.background = '';
        }
        // console.log(this.table.rows);
    },
    mergeTd() {
        let rows = this.table.rows;
        for (let i = 0; i < rows.length; i++) {
            for (let j = 0; j < rows[i].length; j++) {
                let p = rows[i][j];
                if (p.select == true) {
                    let crows = [];

                }

            }
        }
    },
    tableChange() {
        for (let i = 0; i < this.table.rows.length; i++) {
            for (let j = 0; j < this.table.rows[i].length; j++) {
                this.table.rows[i][j].style.height = this.table.rowHeight + 'px';
                // this.table.rows[i][j].style.width = this.table.colWidth+'px';
            }
        }
    },
    tableChange2() {
        this.table.tableStyle.outline = this.table.outWidth + 'px ' + this.table.outlineStyle + ' #000 ';
        this.table.tableStyle.outlineOffset = this.table.outlineOffset + 'px';
        // console.log(this.table.tableStyle);
    },

    //合并单元格选中
    checkCellMethod() {
        let element = this.checkCell;
        element.select_checked = !element.select_checked;
        if (element.select_checked) {
            element.style.background = 'yellow';
        } else {
            element.style.background = '#fff';
        }
        this.tableContextmenu = false;
    },
    // 执行合并单元格操作
    mergeAllCell(type) {
        this.tableCollect[this.currentBox.id].mergeAllCell(type);

    },
    //更新表格
    updateField(rows, cols, element) {
        this.tableContextmenu = false;
        this.topTabActive = '单元格';
        //打开dialog
        this.tableDialog = true;
        //记录当前行
        this.table.current_rows = rows;
        this.table.current_cols = cols;

        // //处理行高和列宽问题
        // this.activeTdRowHeight = this.table.rows[rows][cols].rowHeight;
        // this.activeTdColWidth = this.table.rows[rows][cols].colWidth;
        // this.activeTdUnit = this.table.rows[rows][cols].tdUnit;
        // //计算行高和列宽是否超标
        // let colsWidth = 0;
        // let rowsHeight = 0;
        // //计算总行高
        // this.table.rows.forEach((item, key) => {
        //     rowsHeight += item[0].rowHeight;
        // });
        // //计算总列宽
        // this.table.rows.forEach((item, key) => {
        //     if (key === 0) {
        //         item.forEach((i, k) => {
        //             colsWidth += i.colWidth;
        //         })
        //     }
        // });
        // this.colsWidth = colsWidth;
        // this.rowsHeight = rowsHeight;

        document.getElementById('staticTd').focus();
    },
    setTdField() {

    },
    /**
     * 全局方法
     */
    //系统
    paperChange(cut) {
        this.paper.style.width = this.paper.width - (this.paper.leftEdge + this.paper.rightEdge) + 'mm';
        this.paper.style.height = this.paper.height + 'mm';
        this.paper.style.top = '5mm';
        this.paper.style.left = (BaseData.workareaWidth / 2.5 - this.paper.width) / 2 + 'mm';
        this.paper.zoom = 1;
    },
    zoomChange(num) {
        if (num == 'init') {
            this.paper.zoom = 1;
        } else {
            this.paper.zoom += num;
            this.paper.zoom += num;
            if (this.paper.zoom <= 0.1) {
                this.paper.zoom = 0.1;
            }
            if (this.paper.zoom >= 5) {
                this.paper.zoom = 5;
            }
        }
        let w = this.paper.width * this.paper.zoom;
        let h = this.paper.height * this.paper.zoom;

        this.paper.style.transform = 'scale(' + this.paper.zoom + ',' + this.paper.zoom + ')';

        // this.paper.style.top = 0 - h / 2 + 'mm';
        // this.paper.style.top = this.paper.zoom * 20 + '0mm';
        // this.paper.style.top = parseInt((BaseData.workareaHeight / 2.5 - h) / 2) - this.paper.zoom * 10 + 'mm';
        // this.paper.style.left = parseInt((BaseData.workareaWidth / 2.5 - w) / 2) + zoom * 10 + 'mm';

        // this.ruler.scalex = [];
        // this.ruler.scaley = [];
        // for (var i = 0; i < parseInt(this.ruler.x / this.paper.zoom); i += 1) {
        //     this.ruler.scalex.push(i);
        // }
        // for (var i = 0; i < parseInt(this.ruler.y / this.paper.zoom); i += 1) {
        //     this.ruler.scaley.push(i);
        // }
    },
    // 字段修改
    changeTab(field) {

    },
    transUnitRect(item, true_table) {
        if (item.type === 'table') {
            item.rows.forEach((i, k) => {
                i.forEach((ii, kk) => {
                    if (k === 0) {
                        //转换单元格宽高
                        let unitWidth = true_table.table.rows[k].cells[kk].offsetWidth;
                        ii.style.width = unitWidth + 'px';
                        ii.width = unitWidth;
                    }
                    if (kk === 0) {
                        //转换单元格宽高
                        let unitHeight = true_table.table.rows[k].cells[kk].offsetHeight;
                        ii.style.height = unitHeight + 'px';
                        ii.height = unitHeight;
                    }
                    //转换表格宽高
                })
            });
        } else {
            item.rows.forEach((i, k) => {
                i.forEach((ii, kk) => {
                    //转换单元格宽
                    let unitWidth = true_table.table.rows[0].cells[kk].offsetWidth;
                    ii.style.width = unitWidth + 'px';
                    ii.width = unitWidth;
                    //转换单元格高度
                    // let unitHeight = true_table.table.rows[k].cells[kk].offsetHeight;
                    // ii.style.height = unitHeight + 'px';
                    // ii.height = unitHeight;
                })
            });
        }
        //设置表格宽度
        item.tableStyle.width = '98%';
        item.tableStyle.height = '98%';
    },

    async save(action) {
        this.saveLoading = true;

        //转换表格宽高信息
        this.dragbox.forEach(item => {
            if (item.type != 'table' && item.type != 'shipment' && item.type != 'prepare' && item.type != 'simpleForm') {
                return;
            }
            //解锁控件
            item.isDrag = true;
            //找到dom元素
            let true_table = this.tableCollect[item.id];
            if (true_table === undefined) {
                return;
            }
            if (true_table === undefined) {
                return;
            }

            this.transUnitRect(item, true_table);
        });

        // return;
        let save = {
            html_data: Base64.encode(document.getElementById('paper').innerHTML),
            dragbox: JSON.stringify(this.dragbox),
            text_source: JSON.stringify(this.textSource),
            paper: JSON.stringify(this.paper),
            type: this.formType === '' ? 0 : this.formType,
            id: this.tplId,
            names: this.templateName,
            headers: tools.getToken(),
            text_class: this.templateType,
            // 物流公司id
            logistics_companies_id: this.currentExpress
        };
        if (this.tplId > 0) {
            apis.printSave(save).then((res) => {
                if (res.code != 200) {
                    this.$message({
                        type: 'error',
                        message: res.msg
                    });
                    return;
                }
                this.$message({
                    type: 'success',
                    message: '保存成功！'
                });
            });
            this.saveAfter(action);
            return;
        }
        this.$prompt('请输入模版名称', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
        }).then(({ value }) => {
            save.names = value;
            this.templateName = value;
            const loading = this.$loading({
                lock: true,
                text: '数据保存中',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            apis.printSave(save).then((res) => {
                loading.close();
                if (res.code != 200) {
                    this.$message({
                        type: 'error',
                        message: res.msg
                    });

                    return;
                }
                this.$message({
                    type: 'success',
                    message: '保存成功！'
                });
                this.tplId = res.data.id;

                this.saveAfter(action);
            });
        }).catch(() => {
            this.$message({
                type: 'info',
                message: '取消输入'
            });
        });

    },
    saveAfter(action) {
        // 保存打印
        if (action == 'print') {
            console.log(this.templateType);
            if (this.templateType == 2) {
                this.$alert('连续打印模版需要导入数据才能打印，请返回列表页导入数据后进行打印！');
                return;
            }
            this.printTpl();
        }
        // 保存退出
        if (action == 'exit') {
            setTimeout(() => {
                this.backToList();
            }, 1000);
        }

        this.saveLoading = false;
    },
    backToList() {
        this.$router.push({
            path: '/print'
        })
    },
    printLodopExit() {
        this.printFlag = false;
    },
    printTpl() {
        //打印
        // this.printUrl = '/print_lodop?id=' + this.tplId;
        this.printFlag = true;
    },
    changeForm(id) {
        this.form_list.forEach((item, index) => {
            if (item.id == id) {
                this.form_list[index].active = 1;
            } else {
                this.form_list[index].active = 0;
            }
        });
        this.currentTools = id;
    },

    //设置选中操作,id是拖拽控件的uuid，icon是工具类型
    editBox(e, id) {
        if (typeof e == 'string') {
            this.setBoxActivate(e);
        }
        if (!(e instanceof Event)) {
            this.setBoxActivate(id);
            let o = this.currentBox.boxObject;
            o.width = e.width;
            o.height = e.height;
            o.left = e.left;
            o.top = e.top;
            o.oldTop = o.top;
            o.oldLeft = o.left;

            if (this.currentBox.type == 'text') {
                document.getElementById('textInput').focus();
            }
        }
        //如果是发货单，备货单，显示实际值
        // console.log(this.currentBox.type);
        if (this.currentBox.type === 'simpleForm' || this.currentBox.type === 'prepare' || this.currentBox.type === 'shipment' || this.currentBox.type === 'table') {
            this.borderLine = this.currentBox.borderLine;
            this.printEmptyLine = this.currentBox.emptyLine;
            this.printPageSize = this.currentBox.pageSize;
            //表格布局操作事件
            this.tableListen();
        }
        // if (this.currentBox.type !== 'simpleForm') {
        //     this.showFormTools = false;
        // }
        // if (this.currentBox.type === 'table') {
        // }
    },

    checkSimpleForm() {

    },
    setBoxActivate(id) {
        var box = this.dragbox[this.dragbox.findIndex(item => item.id === id)];
        this.currentBox = box;
        this.currentTools = box.icon;
        this.setActive(id);
        this[box.type] = box;
        box.isActive = true;

        if (this.currentFormItem.id > 0) {
            this.currentFormItem.active = 1;
        }
        this.dragbox.forEach((item, index) => {
            if (item.id != id) {
                item.isActive = false;
            }
        });

    },

    boxMicroReverse(field, type) {
        let cbx = this.currentBox.boxObject;
        this.reverTimer = setInterval(() => {
            if (field == "micro_left") {
                cbx.left += type;
            }
            if (field == "micro_top") {
                cbx.top += type;
            }
        }, 68);
    },
    boxMicroReverseStop() {
        clearInterval(this.reverTimer);
    },
    // 将数值样式转换为css样式
    boxReverse(field, type) {
        let cbx = this.currentBox.boxObject;
        let cbr = this.currentBox.boxReverse;

        // 边框设置
        if (cbx.borderStyle != 'none') {
            cbr.border = cbx.borderWidth + 'px ' + cbx.borderStyle + ' #000';
        } else {
            cbr.border = cbx.borderStyle;
        }
        // 旋转
        if (type == 'deg') {
            //打印方向
            if (field == '+0') {
                this.currentBox.isLimit = true;
                cbx.deg = 0;
            } else if (field == '+90') {
                cbx.deg = 90;
                this.currentBox.isLimit = false;
            }
            // 旋转
            cbr.transform = 'rotate(' + cbx.deg + 'deg)';
            return;
            if (field == '+') {
                cbx.deg += cbx.eachDeg;
            } else if (field == '-') {
                cbx.deg -= cbx.eachDeg;
            } else if (field == 'l') {
                cbx.l += 180;
            } else if (field == 'v') {
                cbx.v += 180;
            }
            cbr.transform = 'rotateY(' + cbx.l + 'deg) rotate(' + cbx.deg + 'deg) rotateX(' + cbx.v + 'deg)';
        }
    },
    //更新样式字段
    updateText(field, type, obj) {
        let ctx = this.currentBox.textObject;
        let cbr = this.currentBox.boxReverse;

        if (obj) {
            ctx = this.table.rows[this.table.current_rows][this.table.current_cols].style;
        }
        if (field == 'fontFamily') {
            ctx.fontFamily = this.currentBox.textObject.fontFamily;
            console.log(ctx.fontFamily);
            console.log(ctx);
        }

        if (field == 'fontSize') {
            ctx.fontSize = ctx.fontSizeNum + 'pt';
        }
        // 转换大小写,仅针对
        if (type == 'trans') {
            ctx.textTransform = field;
            if (field == 'uppercase') {
                ctx.color = 'blue';
            } else {
                ctx.color = 'black';
            }
        }
        // 居中
        if (type == 'align') {
            cbr.textAlign = field;
        }
        // 线
        if (type == 'line') {
            if (field == 'underline') {
                if (ctx.fontLineCount % 2 == 0) {
                    ctx.textDecoration = field;
                } else {
                    ctx.textDecoration = 'none';
                }
                ctx.fontLineCount++;
            }
            if (field == 'line-through') {
                if (ctx.fontLine2Count % 2 == 0) {
                    ctx.textDecoration = field;
                } else {
                    ctx.textDecoration = 'none';
                }
                ctx.fontLine2Count++;
            }
        }

        if (field == 'fontWeight') {
            if (ctx.fontWeightCount % 2 == 0) {
                ctx.fontWeight = 'bold';
            } else {
                ctx.fontWeight = 'normal';
            }
            ctx.fontWeightCount++;
        }
        if (field == 'fontStyle') {
            if (ctx.fontStyleCount % 2 == 0) {
                ctx.fontStyle = 'italic';
            } else {
                ctx.fontStyle = 'normal';
            }
            ctx.fontStyleCount++;
        }
    },
    tableUpdateText(field, type) {
        let ctx = this.table.rows[this.table.current_rows][this.table.current_cols].style;
        let cto = this.table.rows[this.table.current_rows][this.table.current_cols];
        // console.log(this.table.current_rows + '---' + this.table.current_cols);
        if (field == 'fontFamily') {
            ctx.fontFamily = this.currentBox.textObject.fontFamily;
        }
        if (field == 'fontSize') {
            ctx.fontSize = this.currentBox.textObject.fontSizeNum + 'pt';
        }
        // 转换大小写
        if (type == 'trans') {
            ctx.textTransform = field;
        }
        // 居中
        if (type == 'align') {
            ctx.textAlign = field;
        }
        // 线
        if (type == 'line') {
            if (field == 'underline') {
                if (ctx.fontLineCount % 2 == 0) {
                    ctx.textDecoration = field;
                } else {
                    ctx.textDecoration = 'none';
                }
                ctx.fontLineCount++;
            }
            if (field == 'line-through') {
                if (ctx.fontLine2Count % 2 == 0) {
                    ctx.textDecoration = field;
                } else {
                    ctx.textDecoration = 'none';
                }
                ctx.fontLine2Count++;
            }
        }

        if (field == 'fontWeight') {
            if (ctx.fontWeightCount % 2 == 0) {
                ctx.fontWeight = 'bold';
            } else {
                ctx.fontWeight = 'normal';
            }
            ctx.fontWeightCount++;
        }
        if (field == 'fontStyle') {
            if (ctx.fontStyleCount % 2 == 0) {
                ctx.fontStyle = 'italic';
            } else {
                ctx.fontStyle = 'normal';
            }
            ctx.fontStyleCount++;
        }

        this.table.rows[this.table.current_rows][this.table.current_cols].style = ctx;
    },
    // 将数值样式转换为css样式
    tableReverse() {
        if (this.activeBorder != 'all') {
            this.table.rows[this.table.current_rows][this.table.current_cols].style[this.activeBorder] = this.activeBorderWidth + "px " + this.activeBorderStyle + " #000";
        } else {
            this.borderList.forEach(item => {
                if (item.value != 'all') {
                    this.table.rows[this.table.current_rows][this.table.current_cols].style[item.value] = this.activeBorderWidth + "px " + this.activeBorderStyle + " #000";
                }
            });
        }
    },
    tableReverseMutiple(field) {
        // this.table.current_rows;
        // this.table.current_cols;
        // if you get number of row's height ,you should get all the cols in current rows
        // like get 0,1 ,then you should set 0-∞ td's height of you get height
        //先判断是否超宽
        let colsWidth = 0;
        let rowsHeight = 0;
        //计算总行高
        if (field == 'rows') {
            this.table.rows.forEach((item, key) => {
                rowsHeight += item[0].rowHeight;
            });
        }
        //计算总列宽
        if (field == 'cols') {
            this.table.rows.forEach((item, key) => {
                if (key === 0) {
                    item.forEach((i, k) => {
                        colsWidth += i.colWidth;
                    })
                }
            });
        }

        let current = this.table.rows[this.table.current_rows][this.table.current_cols];

        if (field == 'cols') {
            //根据输入的字段重新计算列宽
            colsWidth = colsWidth - current.colWidth + this.activeTdColWidth;
            this.colsWidth = colsWidth;
            // if (colsWidth > 100) {
            //     this.$message({
            //         message: '总列宽超限100%了，请缩小其他列列宽后再增加本列列宽！',
            //         type: 'error'
            //     });
            //     return;
            // }

            this.table.rows.forEach((item) => {
                item[this.table.current_cols].colWidth = this.activeTdColWidth;
            });
        }

        if (field == 'rows') {
            //根据输入的字段重新计算行高
            rowsHeight = rowsHeight - current.rowHeight + this.activeTdRowHeight;
            this.rowsHeight = rowsHeight;
            // if (rowsHeight > 100) {
            //     this.$message({
            //         message: '总行高超限100%了，请缩小其他行行高后再增加本行行高！',
            //         type: 'error'
            //     });
            //     return;
            // }

            this.table.rows.forEach((item, key) => {
                if (key == this.table.current_rows) {
                    item.forEach((i, k) => {
                        i.rowHeight = this.activeTdRowHeight;
                    })
                }
            });
        }

        this.table.rows.forEach((rows) => {
            rows.forEach((cols) => {
                let unit = this.activeTdUnit;
                cols.tdUnit = unit;
                if (field == 'rows') {
                    cols.style.height = cols.rowHeight + unit;
                    // console.log(cols.style.height);
                } else {
                    cols.style.width = cols.colWidth + unit;
                }
            })
        });


    },
    changeLineDirection() {
        let tmp = 0;
        let tmp2 = 0;
        // 
        tmp = this.lineBox.boxObject.width;
        this.lineBox.boxObject.width = this.lineBox.boxObject.height;
        this.lineBox.boxObject.height = tmp;
        // 
        tmp2 = this.lineBox.lineStyle.width;
        this.lineBox.lineStyle.width = this.lineBox.lineStyle.height;
        this.lineBox.lineStyle.height = tmp2;
        this.changeLine();
    },
    changeLine() {
        console.log(this.lineBox.lineDirection);
        if (this.lineBox.lineDirection == 1) {
            this.lineBox.lineStyle.borderBottom = this.lineBox.height + "px " + this.lineBox.borderStyle + " #000";
            this.lineBox.lineStyle.borderLeft = 'none';
            console.log(this.lineBox.lineStyle.borderBottom)
        } else {
            this.lineBox.lineStyle.borderLeft = this.lineBox.height + "px " + this.lineBox.borderStyle + " #000";
            this.lineBox.lineStyle.borderBottom = 'none';
            console.log(this.lineBox.lineStyle.borderLeft)
        }
    },
    /**
     * 统一删除动作
     */
    removeBox(id) {
        //变量 
        this.dragbox.splice(this.dragbox.findIndex(item => item.id === id), 1);
        this.currentBox = tools.copy(BaseData.widgetTemplate);
    },
    //统一还原选中区域
    clearObj() {
        //清空模版
        this.text = tools.copy(this.tmp.text);
        this.image = tools.copy(this.tmp.image);
        this.qr = tools.copy(this.tmp.qr);
        this.barCode = tools.copy(this.tmp.barCode);
        this.table = tools.copy(this.tmp.table);
        this.lineBox = tools.copy(this.tmp.lineBox);
        // this.currentBox = tools.copy(BaseData.widgetTemplate);
        this.dragbox.forEach((item, index) => {
            item.isActive = false;
        })
        this.activeId = 0;
    },
    setActive(id) {
        this.activeId = id;
    },
    selectClose() {
        //关闭选择，回退到列表
        this.$router.replace({
            path: '/print'
        })
    },
    //判断是否有动态数据
    hasDynamicData() {
        if (this.textSource.length == 0) {
            this.$confirm('未检测到动态数据源，是否马上上传？', '温馨提示', {
                type: 'info',
                callback: (action, instance) => {
                    if (action == 'confirm') {
                        this.showTextSource();
                    }
                }
            })
        }
    },

    // 改变单元格数据形态
    changeUnitDataType(unit) {
        unit.valueShow = ''
        unit.source = ''
    },

    //修改纸张
    changePaperType() {
        let p = this.paperType[this.paperType.findIndex(item => item.id === this.paper.paperType)];
        this.paper.width = p.width;
        this.paper.height = p.height;
        this.paperChange();
    },
    // 显示创建动态数据界面
    showTextSource() {
        this.selectDialog = true;
        this.reloadTextSource = true;
    },
    //导入Excel
    // 获取文件
    change_file(e) {
        //自定义单据名称
        // this.templateName = '自定义Excel模版';
        if (this.$refs.file && !this.$refs.file.files[0]) {
            return false
        };
        const selected_file = this.$refs.file.files[0];
        this.fileExcel.oldFile = selected_file;
        this.file_name = selected_file.name;
        this.file_size = selected_file.size ? (selected_file.size / 1024).toFixed(2) : 0;
        this.readFule(selected_file)
    },
    readFule(file) {
        var name = file.name;
        var reader = new FileReader();
        reader.onload = (e) => {
            //重置file
            this.clearExcel = false;
            //重置表头数组
            this.textSource = [];
            this.fileExcel.oldFile = e.target.result;
            let wb = XLSX.read(this.fileExcel.oldFile, { type: "binary" });
            const wb_sheet = wb.Sheets[wb.SheetNames[0]];
            this.selectFileData = XLSX.utils.sheet_to_json(wb_sheet);
            //获取一下纸张规格
            // let t = [];
            // this.selectFileData.forEach((item, key) => {
            //     let oo = item.size.replaceAll('厘米', '').split('x');
            //     let m = {
            //         id: key,
            //         label: item.name,
            //         width: parseInt(oo[0] * 10),
            //         height: parseInt(oo[1] * 10)
            //     };
            //     t.push(m);
            // });
            if (!this.selectFileData.length) {
                this.$alert('数据行不得为空，请添加数据后上传', '错误警告', {
                    confirmButtonText: '确定',
                    callback: action => {
                        this.clearExcel = true;
                    }
                });
                return false
            } else {
                //更新数据源
                let tmp = this.selectFileData[0];
                for (var index in tmp) {
                    //创建textSource数据源
                    let tmp = {
                        'label': index,
                        'value': '$$' + index + '$$'
                    };
                    this.textSource.push(tmp);
                }
                this.confirmExcel = true;
            }
        };
        reader.readAsBinaryString(file);
    },

    // 判断用户是否选择了单据类型
    checkTypeItem() {
        //如果是快递单，继续选择快递公司
        if (this.formType === 0) {
            document.title = `创建普通单据`;
            return;
        }
        //遍历单据列表，根据选中的formType进行数据填充
        this.danju_list.forEach((item, key) => {
            if (item.id === this.formType) {
                if (this.form_list.length === 8) {
                    this.form_list.splice(1, 1);
                }
                this.currentFormItem = item;
                this.form_list.splice(1, 0, item);
            }
        });
        //修改标题
        if (this.formType === 0) {
            document.title = '创建普通单据';
        } else {
            document.title = '创建' + this.currentFormItem.name;
        }

    },

    // 判断用户是否选择了单据类型
    checkType() {
        //如果是快递单，继续选择快递公司
        if (this.formType === 3) {
            //获取快递公司列表
            apis.expressList().then((res) => {
                tools.msg(res, this) && (this.expressList = res.data);
            }).catch((err) => {
                console.log(err);
            });

            this.expressFlag = true;
            return;
        }


        this.getTextSource();

        this.confirmExcel = false;
        this.selectDialog = false;
        this.templateType = 3;
    },
    checkSelectExpress() {
        if (this.currentExpress === '') {
            this.$alert('请选择一个快递公司');
            return;
        }

        this.expressList.forEach(element => {
            if (element.id === this.currentExpress) {
                this.currentExpressName = element.name;
            }
        });

        //修改标题
        document.title = `创建 ${this.currentExpressName} 快递单`;

        this.confirmExcel = false;
        this.selectDialog = false;
        this.templateType = 3;
        this.expressFlag = false;
    }
}