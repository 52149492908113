<template>
    <div>
        <Alibaba v-if="$platform==='alibaba'"></Alibaba>
        <div style="width:100%;min-height:1080px;margin:0px auto 0;background:#f5f9ff;" >
                <div id="myeditor" class="myeditor" >
                    <!-- 顶部工具栏 -->
                    <top-tools ></top-tools>

                    <div class="tools" :style="{height:workarea.style.height}">
                        <ul>
                            <li :key="item.id" 
                                v-show="item.id != 3"
                                v-for="item in form_list" @click="changeForm(item.id)"
                                :class="{active:currentTools == item.id}">
                                <div class="icons icon iconfont " :class="item.icon">
                                </div>
                                <div class="icons_text">{{item.text}}</div>
                            </li>
                        </ul>
                    </div>

                    <div class="workarea" 
                    @click.self="clearObj()"
                    v-on:key.left="boxReverse('',-1)"
                    v-on:key.right="boxReverse('micro_left',1)"
                    :style="workarea.style" >
                        <!-- 放大缩小按钮 -->
                        <div style="position:absolute;right:0;top:20px;width:120px;height:32px;z-index:999;" v-show="zoomButton">
                            <el-button icon="icon iconfont icon-suoxiao" size="mini" circle="" title="缩小" @click="zoomChange(-0.1)"></el-button>
                            <el-button icon="icon iconfont icon-fangda" size="mini" circle="" title="放大" @click="zoomChange(0.1)"></el-button>
                            <el-button icon="icon iconfont el-icon-refresh-right" size="mini" style="font-weight:bold;" circle="" title="重置" @click="zoomChange('init')"></el-button>
                        </div>
                        <!-- 标尺 -->
                        <vue-ruler :scalex="ruler.scalex" :scaley="ruler.scaley" :x="ruler.x" :y="ruler.y" :isShow="paper.ruler" :paperZoom="paper.zoom"></vue-ruler>

                        <div id="paper" @click.self="clearObj()" :style="paper.style">
                            <div class="paper_" @click.self="clearObj()">
                                <img @click.self="clearObj()" style="background:#fff;width:100%;height:100%;position:absolute;top:0px;left:0px;" :src="paper.bg" v-show="!!paper.bg" />
                                <!-- 拖拽元素 -->
                                <VueDragResize 
                                    @dragstop="editBox($event,item.id)"
                                    @resizestop="editBox($event,item.id)"
                                    @clicked="editBox($event,item.id)"
                                    @dragstart="dragboxStart"
                                    @dragging="dragboxing"
                                    :key="item.id" 
                                    :minw="30" 
                                    :minh="5" 
                                    :isDraggable="item.isDrag"
                                    :x="item.boxObject.left"
                                    :y="item.boxObject.top"
                                    :w="item.boxObject.width"
                                    :h="item.boxObject.height"
                                    v-for="item in dragbox"
                                    class="form_drap  drapEle" 
                                    :isActive="item.isActive" 
                                    :isResizable="item.isActive" 
                                    :parentLimitation="item.isLimit"
                                    :class="{ form_drap1: item.id == activeId}" 
                                    :style="item.boxReverse">
                                    <!-- 线条 -->
                                    <div v-if="item.type == 'lineBox'" :style="item.lineStyle" ></div>
                                    <!-- 文本框 -->
                                    <span :style="item.textObject"  v-if="item.type == 'text'">
                                        <span :class="{animation: (item.id == activeId && item.type == 'text')}">
                                            <pre style="display:inline;font-family:inherit;font-size:inherit;">{{item.text}}</pre>
                                            </span><span v-if="!!item.source && !!item.text">:</span> 
                                            <span v-if="!!item.source" style="color:orange;font-weight:900;">{{getSouceText(item.source)}}</span>
                                        </span>
                                    <!-- 图片 -->
                                    <img :src="item.url"  v-if="item.type == 'image'" style="width:100%;height:100%;z-index:-1;" />
                                    <!-- 二维码 -->
                                    <img :src="item.url"  v-if="item.type == 'qr'" style="width:100%;height:100%;z-index:-1;" />
                                    <!-- 条形码 -->
                                    <img :src="item.url"  v-if="item.type == 'barCode'" style="width:100%;height:100%;z-index:-1;" />
                                    <!-- 表格生成 -->
                                    <div v-if="item.type == 'table'" style="position:relative;width:100%;height:100%;">
                                        <table :class="item.myClassName" :style="item.tableStyle">
                                            <tr v-for="(v, rows) in item.rows" :key="rows">
                                                <td 
                                                    :rowspan="x.rowspan"
                                                    :colspan="x.colspan"
                                                    v-for="(x,cols) in v" :key="cols"  :style="x.style" 
                                                    @click="updateField(rows,cols,x)" 
                                                    :class="{tdActive:rows==item.current_rows&&cols==item.current_cols,animation2:rows==item.current_rows&&cols==item.current_cols}">
                                                    &nbsp;
                                                    <pre style="display:inline;font-family:inherit;font-size:inherit;">{{x.valueShow}}{{x.source && getSouceText(x.source)}}&nbsp;</pre>
                                                </td>
                                            </tr>
                                        </table>
                                        <div 
                                            :style="{color:item.isDrag?'green':'red'}"
                                            @click="changeDrag(item)"
                                            style="height:25px;width:100px;line-height:25px;text-align:center;position:absolute;left:0px;top:-30px;
                                            border:1px solid #ccc;cursor:pointer;font-weight:900;">
                                            <span v-if="item.isDrag">
                                                点击禁止拖拽
                                            </span>
                                            <span v-if="!item.isDrag">
                                                <span v-if="item.isActive">
                                                    点击打开拖拽
                                                </span>
                                                <span v-if="!item.isActive">
                                                    已锁定,点击解锁
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                    <!-- 发货单生成 -->
                                    <div v-if="item.type == 'shipment'" class="mytable" style="position:relative;height:150px;width:100%;" >
                                        <table :style="item.tableStyle" :class="item.myClassName" style="width:100%;" >
                                            <tr>
                                                <td v-for="(i,cols) in item.rows[0]" :style="i.style" :key="cols" >
                                                    {{i.valueShow}}
                                                </td>
                                            </tr>
                                            <tr v-for="(is,ks) in item.rows" :key="ks" v-show="ks!=0 && ks!=item.rows.length-1">
                                                <td :colspan="item.rows[0].length"  :style="item.rows[0][1]?item.rows[0][1].style:''">
                                                    <span style="color:blue;font-weight:900;">-----------$$商品列表$$-----------</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td v-for="(i,cols) in item.rows[item.rows.length-1]" :style="i.style" :key="cols" >
                                                    {{i.valueShow}}
                                                </td>
                                            </tr>
                                        </table>
                                        <div 
                                            :style="{color:item.isDrag?'green':'red'}"
                                            @click="changeDrag(item)"
                                            style="height:25px;width:100px;line-height:25px;text-align:center;position:absolute;left:0px;top:-30px;
                                            border:1px solid #ccc;cursor:pointer;font-weight:900;">
                                            <span v-if="item.isDrag">
                                                点击禁止拖拽
                                            </span>
                                            <span v-if="!item.isDrag">
                                                <span v-if="item.isActive">
                                                    点击打开拖拽
                                                </span>
                                                <span v-if="!item.isActive">
                                                    已锁定,点击解锁
                                                </span>
                                            </span>
                                        </div>
                                    </div>

                                    <!-- 发货单生成 -->
                                    <div v-if="item.type == 'simpleForm'" class="mytable" style="position:relative;height:150px;width:100%;" >
                                        <table :style="item.tableStyle" :class="item.myClassName" style="width:100%;" >
                                            <tr>
                                                <td v-for="(i,cols) in item.rows[0]" :style="i.style" :key="cols" >
                                                    {{i.valueShow}}
                                                </td>
                                            </tr>
                                            <tr v-for="(is,ks) in item.rows" :key="ks" v-show="ks!=0 && ks!=item.rows.length-1">
                                                <td :colspan="item.rows[0].length" :style="item.rows[0][1]?item.rows[0][1].style:''" >
                                                    <span style="color:blue;font-weight:900;">-----------$$商品列表$$-----------</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td v-for="(i,cols) in item.rows[item.rows.length-1]" :style="i.style" :key="cols" >
                                                    {{i.valueShow}}
                                                </td>
                                            </tr>
                                        </table>
                                        <div 
                                            :style="{color:item.isDrag?'green':'red'}"
                                            @click="changeDrag(item)"
                                            style="height:25px;width:100px;line-height:25px;text-align:center;position:absolute;left:0px;top:-30px;
                                            border:1px solid #ccc;cursor:pointer;font-weight:900;">
                                            <span v-if="item.isDrag">
                                                点击禁止拖拽
                                            </span>
                                            <span v-if="!item.isDrag">
                                                <span v-if="item.isActive">
                                                    点击打开拖拽
                                                </span>
                                                <span v-if="!item.isActive">
                                                    已锁定,点击解锁
                                                </span>
                                            </span>
                                        </div>
                                    </div>

                                    <div v-if="item.type == 'prepare'" style="position:relative;height:150px;width:100%;" >
                                        <table :style="item.tableStyle" :class="item.myClassName" style="width:100%;">
                                            <tr>
                                                <td v-for="(i,cols) in item.rows[0]" :style="i.style" :key="cols" >
                                                    {{i.valueShow}}
                                                </td>
                                            </tr>
                                            <tr v-for="(is,ks) in item.rows" :key="ks" v-show="ks!=0 && ks!=item.rows.length-1">
                                                <td :colspan="item.rows[0].length" :style="item.rows[0][1]?item.rows[0][1].style:''" >
                                                    <span style="color:blue;font-weight:900;">-----------$$商品列表$$-----------</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td v-for="(i,cols) in item.rows[item.rows.length-1]" :style="i.style" :key="cols" >
                                                    {{i.valueShow}}
                                                </td>
                                            </tr>
                                        </table>
                                        <div 
                                            :style="{color:item.isDrag?'green':'red'}"
                                            @click="changeDrag(item)"
                                            style="height:25px;width:100px;line-height:25px;text-align:center;position:absolute;left:0px;top:-30px;
                                            border:1px solid #ccc;cursor:pointer;font-weight:900;">
                                            <span v-if="item.isDrag">
                                                点击禁止拖拽
                                            </span>
                                            <span v-if="!item.isDrag">
                                                <span v-if="item.isActive">
                                                    点击打开拖拽
                                                </span>
                                                <span v-if="!item.isActive">
                                                    已锁定,点击解锁
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                    <!-- 关闭组件按钮 -->
                                    <div class="drap_close el-icon-circle-close" v-if="item.isActive" @click="removeBox(item.id)"></div>
                                    <!-- 拖拽组件 -->
                                    <!-- <div class="drag_handle icon iconfont icon-drag" @click="editBox(item.id)"></div> -->
                                </VueDragResize>
                            </div>
                        </div>
                    </div>  
                    <!-- 右侧工具栏 -->
                    <!-- 右侧工具栏 -->
                    <!-- 右侧工具栏 -->
                    <right-tools></right-tools>
                </div>
            <!-- 合并单元格 -->
            <el-dialog
            :modal-append-to-body="false"
            title="合并单元格"
            :visible.sync="mergeTable"
            width="70%"
            center>
            <el-row style="position:relative;">
                <div :style="{width:table.boxObject.width+'px',height:table.boxObject.height+'px'}" style="margin:50px auto 0;">
                    <table  style="margin:5px auto;width:100%;cursor:crosshair;">
                        <tr v-for="(v, rows) in table.rows" :key="rows">
                            <td v-for="(x,cols) in v" :key="cols"  :style="x.style"  @click="selectTd($event,rows,cols)">
                                {{x.valueShow}}&nbsp;
                            </td>
                        </tr>
                    </table>
                </div>
            </el-row>
            <span slot="footer" class="dialog-footer">
                <!-- <el-button >还 原</el-button> -->
                <el-button type="primary" @click="mergeTd">开始合并</el-button>
            </span>
            </el-dialog>

            <!-- 处理边缘提示 -->
            <el-dialog
            :modal-append-to-body="false"
            title="什么是处理边缘？"
            :visible.sync="showEdge"
            width="50%"
            center>
            <div style="text-align:center;">
                <p style="width:100%;font-size:20px;line-height:40px;">边缘指的是一些打印纸在生产时在纸张的左右预留的空白这个边缘会影响纸张的实际大小。</p>
                <p style="width:100%;font-size:20px;line-height:40px;">假设纸张标称80mm，边缘左右分别是1mm，则纸张实际可编辑宽度为78mm</p>
                <img src="../../assets/img/home/paper_edge.png" />
            </div>
            </el-dialog>

            <!-- 处理边缘提示 -->
            <el-dialog
            :modal-append-to-body="false"
            title="什么是条形码的最小宽度？"
            :visible.sync="showMinWidth"
            width="50%"
            center>
            <div style="text-align:center;">
                <p style="width:100%;font-size:20px;line-height:40px;">什么是条形码的最小宽度指的是条形码中每个条形单元的最小宽度</p>
                <p style="width:100%;font-size:20px;line-height:40px;">建议最小宽度大于2，否则低精度打印机打印的条形码可能无法被识别</p>
                <img src="../../assets/img/home/barcode_minwidth.png" />
            </div>
            </el-dialog>

            <!-- 处理边缘提示 -->
            <el-dialog
            :modal-append-to-body="false"
            title="不同的打印机处理线条的差异"
            :visible.sync="showLineWidth"
            width="50%"
            center>
            <div style="text-align:center;">
                <p style="width:100%;font-size:20px;line-height:40px;">如果您采用针式打印机，建议把线条设为1px，否则可能会损坏纸张</p>
                <p style="width:100%;font-size:20px;line-height:40px;">如果使用普通激光打印机和热敏打印机则没有限制</p>
            </div>
            </el-dialog>

            <!-- 单据类型 -->
            <el-dialog
            :modal-append-to-body="false"
            title="选择单据类型"
            :visible.sync="selectDialog"
            :before-close="selectClose"
            v-loading="!loadPrintAuth"
            width="880px"
            center>
                <el-row class="danju_row">
                    <div class="danju_box" v-show="isErp == 2" style="width:230px;">
                        <div class="djword">系统单据</div>
                        <div class="djaction">
                            <el-select v-model="formType" placeholder="请选择" size="small" style="width:60%;height:40px;margin:0;text-align:center;">
                                <el-option
                                v-for="(item,key) in danju_list"
                                :key="key"
                                @click.native="checkTypeItem"
                                :label="item.name"
                                :value="item.id">
                                </el-option>
                            </el-select>
                            <el-button 
                            @click="checkType"
                            size="small" type="primary" style="margin-left:10px;">创建</el-button>
                        </div> 
                    </div>
                    <div class="danju_box" :class="{isErp:isErp==2,notErp:isErp==1}" >
                        <div class="djword">单张打印</div>
                        <div class="djaction" style="position:relative;">
                            <el-button :type="singlePrint?'primary':'disabled'" size="small" style="font-weight:600" :disabled="!singlePrint"   @click="confirmExcel=false;selectDialog=false;templateType=1;">快速创建</el-button>
                            <!-- <div id="excel_input"><input type="file" class="filexls" ref="file" name="file" accept=".xlsx,.xls" @change="change_file" v-if="clearExcel"></div> -->
                            <!-- <div style="height:18px;line-height:18px;color:#999;">或</div> -->
                            <!-- <div style="height:30px;line-height:30px;color:blue;cursor:pointer;" title="直接创建静态模版，不需要动态数据" @click="confirmExcel=false;selectDialog=false;">创建静态模版</div> -->
                        </div>
                    </div>
                    <div class="danju_box" :class="{isErp:isErp==2,notErp:isErp==1}">
                        <div class="djword">连续打印</div>
                        <div class="djaction" style="position:relative;" >
                            <el-button :type="mutiplePrint?'primary':'disabled'" size="small" :disabled="!mutiplePrint" style="font-weight:600" @click="templateType=2;">导入Excel<i class="el-icon-upload2 el-icon--right"></i></el-button>
                            <div id="excel_input"><input type="file" :disabled="!mutiplePrint"  class="filexls" ref="file" name="file" accept=".xlsx,.xls" @change="change_file" v-if="clearExcel"></div>
                            <a style="position:absolute;left:34px;bottom:-35px;cursor:pointer;user-select:none;" @click="printTip" v-if="!mutiplePrint">输入邀请码获得权限</a>
                            <!-- <div style="height:18px;line-height:18px;color:#999;">或</div> -->
                            <!-- <div style="height:30px;line-height:30px;color:blue;cursor:pointer;" title="直接创建静态模版，不需要动态数据" @click="confirmExcel=false;selectDialog=false;">创建静态模版</div> -->
                        </div>
                    </div>
                </el-row>
            </el-dialog>
            <!-- 确认表格表头 -->
            <el-dialog
            :modal-append-to-body="false"
            title="请确认表格表头是否正确"
            :visible.sync="confirmExcel"
            width="70%"
            center>
                <el-row class="danju_row" style="width:80%">
                    <el-table  border align="center">
                        <el-table-column v-for="(item,key) in textSource" :key="key"
                            :label="item.label">
                        </el-table-column>
                    </el-table>
                </el-row>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="selectDialog = true;confirmExcel = false;clearExcel=true; ">重新上传</el-button>
                    <el-button type="primary" @click="selectDialog = false;confirmExcel = false;clearExcel=true; templateType=2;">确 定</el-button>
                </span>
            </el-dialog>

            <!-- 选择快递公司 -->
            <el-dialog
            :modal-append-to-body="false"
            title="选择快递类型"
            :visible.sync="expressFlag"
            v-if="expressFlag"
            width="40%"
            center>
                <div style="text-align:center;">
                    <p style="width:100%;font-size:16px;line-height:40px;height:40px;">请选择快递公司</p>
                    <el-select v-model="currentExpress" filterable placeholder="请选择">
                        <el-option
                        v-for="(item,key) in expressList"
                        :label="item.name"
                        :value="item.id"
                        :key="key">
                        </el-option>
                    </el-select>
                    <p style="margin-top:30px;">
                        <el-button type="primary" @click="checkSelectExpress">马上创建</el-button>
                    </p>
                </div>  
            </el-dialog>

            <!-- 卡片信息 -->
            <div 
            class="tableContextmenuStyle"
            v-show="tableContextmenu"
            :style="tableContextmenuStyle">
                <div class="contextItem" @click="checkCellMethod">
                    选中/取消 当前单元格
                </div>
                <div class="contextItem" style="color:blue;" @click="mergeAllCell(1)">
                    <b>横向合并单元格</b>
                </div>
                <div class="contextItem" style="color:blue;" @click="mergeAllCell(2)">
                    <b>纵向合并单元格</b>
                </div>
                <!-- <div class="contextItem" style="color:blue;" @click="mergeAllCell(3)">
                    <b>合并全部选中单元格</b>
                </div> -->
            </div>
            <!-- 打印 -->
            <el-dialog
            :modal-append-to-body="false"
            title="直接打印"
            :visible.sync="printFlag"
            v-if="printFlag"
            width="60%"
            center>
                <printStatic style="height:550px;" :printId="tplId" @printLodopExit="printLodopExit"></printStatic>
            </el-dialog>
            <!-- 接收子组件信息 -->
            <alikey></alikey>
            </div>
    </div>
</template>
<script>
import VueDragResize from "vue-drag-resize";
import EditorObject from "./collect/objectData.js";
import EditorMethod from "./collect/methodObject.js";
import topTools from './collect/top_tools.vue';
import rightTools from './collect/right_tools.vue';
import tableTools from './collect/table_tools.vue';
import Dialog from './collect/Dialog.vue';
import VueRuler from './collect/ruler.vue';
import printStatic from './printStatic.vue';
import Alikey from '../../components/common/alikey.vue';
import Alibaba from "../../components/common/alibaba.vue";
import computed from './collect/computed.js';


export default {
    data(){
        return EditorObject;
    },
    methods:EditorMethod,
    components: {
        VueDragResize,topTools,rightTools,tableTools,Dialog,VueRuler,printStatic,Alikey,Alibaba
    },
    inject:['reload'],
    computed:computed,
    destroyed(){
        this.tableCollect = [];
    },
    async mounted() {
        this.init();
    }
}


</script>

<style scoped lang="less">
@import url('../../assets/less/normal');
@import url('./less/editor');
body{
    background:#f5f9ff!important;
}
.animation2::after {
    content:'';
    display: inline-block;
    width:1px;
    height:16px;
    position:relative;
    top:3px;
    left:0px;
    animation: blink 1s infinite steps(1, start)
  }

.animation::after {
    content:'';
    display: inline-block;
    width:1px;
    height:16px;
    position:relative;
    top:3px;
    left:0px;
    animation: blink 1s infinite steps(1, start)
}
@keyframes blink {
 0%{
   background-color: white;
 }
 50% {
   background-color: black;
 }
 100% {
   background-color: white;
 }
}
.tableContextmenuStyle{
    position:fixed;z-index:999999;width:160px;background:#fefefe;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 0.4);padding:5px 0 15px 0;
    .contextItem{
        border-bottom: 1px solid #fff;width:88%;margin:0 auto;height:38px;line-height: 38px;
        cursor: pointer;
    }
    .contextItem:hover{
        border-bottom: 1px solid #ccc;
    }
}

</style>