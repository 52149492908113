export default {
    formListClean: function() {
        let newArr = [];
        /**
         * 
         * 
         */
        return this.form_list;
        this.form_list.forEach((item, key) => {
            // 普通
            if (this.formType === 0 || this.formType === 3) {
                if (!(item.text === '发货单' || item.text === '备货单')) {
                    newArr.push(item);
                }
            } else if (this.formType === 2) {
                if (!(item.text === '发货单')) {
                    newArr.push(item);
                }
            } else if (this.formType === 1) {
                if (!(item.text === '备货单')) {
                    newArr.push(item);
                }
            } else if (this.formType === '') {
                if (!(item.text === '发货单' || item.text === '备货单')) {
                    newArr.push(item);
                }
            }
        });

        return newArr;
    }
}