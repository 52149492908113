import fonts from './font.js';
import BaseData from "./baseData.js";
import tools from "../../../extends/tools";
export default {
    showFormTools: true,
    //选中的发货单字段
    currentShipMentField: [
        'number'
    ],
    tableCollect: {}, //表格控件集合
    tableContextmenu: false,
    tableContextmenuStyle: {
        top: 0,
        left: 0
    },
    //合并单元格被选中的当前单元格
    checkCell: null,

    //表格框线
    showTableBorder: true,
    //发货单数量
    printPageSize: 5,
    //是否打印空行
    printEmptyLine: true,
    // 发货单的动态字段
    shipMentField: [

    ],

    //是否打印框线
    borderLine: true,

    // 选中的备货单字段
    currentPrepareField: [
        'number', 'pic_path', 'title', 'sku_properties_name', 'num', 'buyer_message'
    ],
    // 选中的备货单字段
    prepareField: [

    ],

    // 快递公司列表
    expressList: [],
    // 当前选中的快递公司
    currentExpress: '',
    expressFlag: false,

    // 级联菜单
    options: [{
        value: 'zhinan',
        label: '指南',
        children: [{
            value: 'shejiyuanze',
            label: '设计原则',
        }, {
            value: 'daohang',
            label: '导航',
        }]
    }, {
        value: 'zujian',
        label: '组件',
        children: [{
            value: 'basic',
            label: 'Basic',
        }]
    }],
    templateType: 0, //新增的模版类型，1为表单，2为Excel导入
    singlePrint: false,
    mutiplePrint: false,
    loadPrintAuth: false,
    fileUrl: localStorage.getItem('baseUrl') + '/api/admin/file_up',
    authorizationBearer: { 'authorization-bearer': localStorage.getItem('authorization-bearer') },
    tplId: 0, //模版id，自动保存后从后端返回
    templateName: '未命名模版', //模版名称
    templateName2: '未命名模版', //模版名称
    activeIndex2: '1',
    activeClass: {},
    // 单据类型
    formType: '',
    // 物流id
    logistics_companies_id: 0,
    isErp: 1, //用户角色，1为角色1，2为电商打印
    //导入excel信息
    file_name: '',
    file_size: 0,
    showEdge: false,
    fileExcel: {},
    selectFileData: {},
    // 字体大小列表
    fontSzieArr: fonts.fontSzieArr,
    excelField: [],
    //确认表头弹窗
    confirmExcel: false,
    reloadTextSource: false,
    //解决file空间只触发一次问题
    clearExcel: true,
    //组件中被选中的组件id
    activeId: 0,
    //填充单元格数据
    tableDialog: false,
    //选择EXCEL或表单
    selectDialog: true,
    //合并单元格弹窗
    mergeTable: false,
    // 通用的边框类型
    borderStyle: BaseData.borderStyle,
    //缩小放大隐藏按钮
    zoomButton: true,
    // 上传背景图
    uploadBg: false,
    // 工作区参数
    showMinWidth: false,
    saveLoading: false,
    workarea: {
        style: {
            width: BaseData.workareaWidth / 2.5 + 'mm',
            height: BaseData.workareaHeight / 2.5 + 'mm',
        }
    },
    // 显示线条宽度
    showLineWidth: false,
    // 顶部导航条开关
    topTabActive: '通用',
    topTab: BaseData.topTab,
    // 
    borderStyleList: BaseData.borderStyleList,

    //打印参数
    printFlag: false,
    printUrl: '',
    // 定时
    reverTimer: null,
    //动态数据来源
    sourceList: BaseData.sourceList,
    tmp: {
        textBoxTemplate: {},
        imageTemplate: {},
        dynamicQr: {},
        barCode: {},
        table: {},
        lineBox: {}
    },
    //上传图片列表地址
    uploadImages: [

    ],
    //上传二维码列表地址
    uploadQrcode: [

    ],
    //上传的条形码列表地址
    uploadLindecode: [

    ],
    //字体集合
    fontFamily: fonts.fonts,
    // excel数据来源集合
    textSource: [

    ],
    // 系统数据来源

    // 当前选中的对象
    currentBox: BaseData.widgetTemplate,
    //画布里的数据
    dragbox: [

    ],
    paperType: BaseData.paperType,
    //纸张信息
    paper: (() => {
        let paper = {
            width: BaseData.paperWidth / 2.5,
            height: BaseData.paperHeight / 2.5,
            icon: 'icon2',
            //预设
            paperType: '自定义',
            bg: '',
            ruler: false,
            // 纸张缩放倍数
            zoom: 1,
            rightEdge: 0,
            leftEdge: 0,
            isEdge: 1,
            style: {
                width: BaseData.paperWidth / 2.5 + 'mm',
                height: BaseData.paperHeight / 2.5 + 'mm',
                top: '5mm',
                left: (BaseData.workareaWidth / 2.5 - BaseData.paperWidth / 2.5) / 2 + 'mm',
                position: 'absolute',
                background: '#fff',
                transform: 'scale(1,1)',
                transformOrigin: '50% 0'
            }
        };
        return paper;
    })(),
    //尺子对象
    ruler: {
        scalex: [],
        scaley: [],
        x: 286,
        y: 211
    },
    presetLine: [{ type: 'l', site: 10 }, { type: 'v', site: 10 }],
    //线条
    lineBox: (() => {
        let box = tools.copy(BaseData.widgetTemplate);
        box.type = 'lineBox';
        box.icon = 'icon8';
        box.height = 1;
        box.borderStyle = 'solid';
        box.boxObject.width = 400;
        box.boxObject.height = 20;
        box.lineDirection = 1;
        box.lineStyle = {
            borderBottom: "1px solid #000",
            borderLeft: 'none',
            width: '100%',
            height: '1px'
        }
        return box;
    })(),
    //文本模版框
    text: (() => {
        let box = tools.copy(BaseData.widgetTemplate);
        box.type = 'text';
        box.icon = 'icon3';
        return box;
    })(),

    // 备货单控件
    prepare: (() => {
        let box = tools.copy(BaseData.widgetTemplate);
        box.type = 'prepare';
        // 是否打印框线
        box.borderLine = true;
        box.rowHeight = 20;
        box.ctd = {};
        box.colWidth = 60;
        box.icon = 'icon10';
        box.current_rows = 0;
        box.current_cols = 0;
        box.rowNum = 4;
        box.colNum = 4;
        box.boxObject.width = 400;
        box.boxObject.height = 200;
        box.rows = [

        ];
        // 填充默认数据


        return box;
    })(),

    //发货单控件
    //
    shipment: (() => {
        let box = tools.copy(BaseData.widgetTemplate);
        box.type = 'shipment';
        // 是否打印框线
        box.borderLine = true;
        box.rowHeight = 20;
        box.ctd = {};
        box.colWidth = 60;
        box.icon = 'icon9';
        box.current_rows = 0;
        box.current_cols = 0;
        box.rowNum = 4;
        box.colNum = 4;
        box.boxObject.width = 400;
        box.boxObject.height = 200;
        box.rows = [

        ];
        // 填充默认数据
        return box;
    })(),
    simpleForm: (() => {
        let box = tools.copy(BaseData.widgetTemplate);
        box.type = 'simpleForm';
        // 是否打印框线
        box.borderLine = true;
        box.rowHeight = 20;
        box.ctd = {};
        box.colWidth = 60;
        box.icon = 'icon9';
        box.current_rows = 0;
        box.current_cols = 0;
        box.rowNum = 4;
        box.colNum = 4;
        box.boxObject.width = 400;
        box.boxObject.height = 200;
        box.rows = [

        ];
        // 填充默认数据
        return box;
    })(),
    //图片信息
    image: (() => {
        let box = tools.copy(BaseData.widgetTemplate);
        box.type = 'image';
        box.icon = 'icon4';
        box.boxObject.width = 100;
        box.boxObject.height = 100;
        box.url = '';
        return box;
    })(),
    //二维码信息
    qr: (() => {
        let box = tools.copy(BaseData.widgetTemplate);
        box.type = 'qr';
        box.staticText = '';
        box.isDynamic = true;
        box.icon = 'icon5';
        box.boxObject.width = 100;
        box.boxObject.height = 100;
        box.url = require('../../../assets/img/home/dynamicQr.png');
        return box;
    })(),
    //条形码
    barCode: (() => {
        let box = tools.copy(BaseData.widgetTemplate);
        box.type = 'barCode';
        box.icon = 'icon6';
        box.format = '128Auto';
        box.boxObject.width = 300;
        box.boxObject.minWidth = 2;
        box.boxObject.height = 60;
        box.url = require('../../../assets/img/home/dynamicbarCode.png');
        return box;
    })(),
    //
    table: (() => {
        let box = tools.copy(BaseData.widgetTemplate);
        box.type = 'table';
        // 是否打印框线
        box.borderLine = false;
        box.rowHeight = 30;
        box.ctd = {};
        box.colWidth = 100;
        box.icon = 'icon7';
        box.url = require('../../../assets/img/home/dynamicbarCode.png');
        box.current_rows = 0;
        box.current_cols = 0;
        box.rowNum = 4;
        box.colNum = 4;
        box.boxObject.width = 400;
        box.boxObject.height = 200;
        box.rows = [
            [{
                isDynamic: '静态',
                valueShow: '',
                tdStyle: {
                    height: '28px',
                    width: 'auto',
                    border: "1px solid #ccc",
                }
            }]
        ];

        return box;
    })(),
    currentFormItem: {},
    //公共数据
    danju_list: BaseData.danju,
    currentTools: 'icon2',
    //左侧按钮列表
    form_list: BaseData.formList,
    lineDirection: [{
            value: 1,
            label: '横线'
        },
        {
            value: 2,
            label: '竖线'
        }
    ],
    // 处理表格行高和列宽单位问题
    activeTdUnit: '%',
    //行高
    activeTdRowHeight: 30,
    // 列宽
    activeTdColWidth: 60,
    //总行高
    colsWidth: 100,
    rowsHeight: 100,
    // 单元格宽高度单位
    tdSizeUnit: [
        // {
        //     value: 'px',
        //     label: 'px'
        // },
        {
            value: '%',
            label: '%'
        }
        // ,
        // {
        //     value: 'mm',
        //     label: 'mm'
        // }
    ],

    borderList: [{
            label: '上边框',
            value: 'borderTop'
        },
        {
            label: '下边框',
            value: 'borderBottom'
        }, {
            label: '左边框',
            value: 'borderLeft'
        }, {
            label: '右边框',
            value: 'borderRight'
        }, {
            label: '统一设置',
            value: 'all'
        }
    ],
    barcodeFormat: BaseData.barcodeFormat,
    activeBorder: 'borderBottom',
    activeBorderStyle: 'solid',
    activeBorderWidth: 1,
    //单据的详细信息数组
    form_detail_source: [],
    // 单据的详细信息选中的字段
    form_detail_checked: [

    ],
    // 单据的通用分页信息
    simplePrintPageSize: 5,
    printSimpleEmptyLine: false,
    simpleBorderLine: false
}