import $ from 'jQuery';

class MyTable {
    constructor(box, host) {
        this.host = host;
        this.table = document.querySelector('.' + box.myClassName);
        this.tableReal = document.querySelector('.' + box.myClassName);
        this.box = box;
        this.id = '';
        //合并单元格被选中的当前单元格
        this.checkCell = null;
        //首行首列单元格标志
        this.start = 1;
        if (this.box.type !== 'table') {
            this.start = 0;
        }
    };

    init() {
        //查看合并后的表格状态
        // console.log(this.table);
        this.simpleColWidth();
        if (this.box.type !== 'table') {
            return;
        }
        this.simpleLineHeight();
        this.crossUnit();
        this.tableEvt();
    }

    /** 
     * 根据用户选中的单元格进行计算合并
     */
    mergeAllCell(type) {
        // 纵向合并
        if (type === 2) {
            //记录被选中的列
            let cols = new Set();
            let rows = new Set();

            this.table.rows.forEach((item, key) => {
                item.cells.forEach((i, k) => {
                    if (i.select_checked === true) {
                        cols.add(k);
                        rows.add(key);
                    }
                });
            });

            let arr = Array.from(rows).sort((a, b) => {
                if (a > b) {
                    return 1;
                }
                if (a === b) {
                    return 0;
                }
                if (a < b) {
                    return -1;
                }
            });
            let colsArr = Array.from(cols);
            this.mergeCell(arr[0], arr[arr.length - 1], colsArr[0]);
        }
        //横向合并
        if (type === 1) {
            //横向合并
            //一次只能合并一行
            let counter = 0;
            this.table.rows.forEach((item, key) => {
                //横向合并
                let rowsObj = {
                    start: -1,
                    end: -1,
                    row: -1
                };
                //
                item.cells.forEach((i, k) => {
                    if (!i.select_checked) {
                        return;
                    }
                    //先判断起始列是否被填充
                    if (rowsObj.start === -1) {
                        rowsObj.start = k;
                    }
                    //先判断起始列是否被填充
                    if (rowsObj.start !== -1) {
                        rowsObj.end = k;
                    }
                    rowsObj.row = key;
                });
                //依次合并
                if (rowsObj.row !== -1) {
                    counter++;
                    if (counter > 1) {
                        this.host.$message({
                            type: 'error',
                            message: '横向合并，一次只能操作一行数据!'
                        })
                        return;
                    }
                    console.log(rowsObj);
                    this.mergeCell2(rowsObj.start, rowsObj.end, rowsObj.row);
                }
            });
        }

        //每次合并后清空合并记录，防止出错
        this.table.rows.forEach((item, key) => {
            item.cells.forEach((i, k) => {
                i.select_checked = false;
                i.style.background = "#fff";
            });
        });

        this.host.tableContextmenu = false;
    }


    //表格基础右键事件
    tableEvt() {
        //禁止右键菜单事件
        document.querySelector('.tableContextmenuStyle').addEventListener('contextmenu', (e) => {
            e.preventDefault();
        });
        //右键
        this.table.rows.forEach((item, key) => {
            item.cells.forEach((i, k) => {
                i.addEventListener('contextmenu', (e) => {
                    this.host.tableContextmenu = true;
                    this.host.tableContextmenuStyle = {
                        top: e.pageY + 'px',
                        left: e.pageX + 'px'
                    }

                    //设置选中当前单元格
                    this.checkCell = i;
                    this.host.checkCell = i;
                    //隐藏默认事件
                    e.preventDefault();
                });
            });
        });
    }

    /*
       合并单元格(如果结束行传0代表合并所有行) *
           @param startRow 起始行 *
           @param endRow 结束行 *
           @param col 合并的列号， 对第几列进行合并(从0开始)。 第一行从0开始 
    */
    mergeCell(startRow, endRow, col) {
        let tb = this.table;
        if (!tb || !tb.rows || tb.rows.length <= 0) {
            return;
        }
        if (col >= tb.rows[0].cells.length || (startRow >= endRow && endRow != 0)) {
            return;
        }
        if (endRow == 0) {
            endRow = tb.rows.length - 1;
        }
        //
        let rowspan = endRow - startRow + 1;
        tb.rows.forEach((item, key) => {
            item.cells.forEach((i, k) => {
                //起始列和指定行
                if (k === col && key === startRow) {
                    this.box.rows[startRow][k].rowspan = rowspan;
                }
            })
        });
        //移除多余的单元格
        //合并多少个
        for (let j = startRow + 1; j <= endRow; j++) {
            // console.log('cells:' + this.box.rows[j][col]);
            let colReal = col >= this.box.rows[j].length - 1 ? col - 1 : col + 1;
            this.box.rows[j].splice(colReal, 1);
            console.log(colReal)
        }

        return;
        // 纵向合并
        for (let i = startRow; i < endRow; i++) {
            if (tb.rows[startRow].cells[col].innerHTML == tb.rows[i + 1].cells[col].innerHTML) {
                //如果相等就合并单元格,合并之后跳过下一行
                tb.rows[i + 1].removeChild(tb.rows[i + 1].cells[col]);
                tb.rows[startRow].cells[col].rowSpan = (tb.rows[startRow].cells[col].rowSpan) + 1;
            } else {
                this.mergeCell(table1, i + 1, endRow, col);
                break;
            }
        }


    }

    /*
        合并单元格(如果结束行传0代表合并所有行) *
            @param startRow 起始行 *
            @param endRow 结束行 *
            @param col 合并的列号， 对第几列进行合并(从0开始)。 第一行从0开始 
    */
    mergeCell2(startCol, endCol, row) {
        let tb = this.table;
        let colspan = endCol - startCol + 1;
        //横向合并
        tb.rows.forEach((item, key) => {
            item.cells.forEach((i, k) => {
                //起始列和指定行
                if (k === startCol && key === row) {
                    this.box.rows[row][k].colspan = colspan;
                }
            })
        });
        //移除多余的单元格
        //合并多少个
        for (let j = startCol + 1; j <= endCol; j++) {
            this.box.rows[row].splice(startCol + 1, 1);
        }

    }

    //
    simpleColWidth() {
        var tTD; //用来存储当前更改宽度的Table Cell,避免快速移动鼠标的问题
        var table = this.table;
        let box = this.box;
        let mouseInit = this.mouseInit;

        for (let j = this.start; j < table.rows[0].cells.length - 1; j++) {
            table.rows[0].cells[j].onmousedown = function(event) {
                if (box.isDrag) {
                    return;
                }
                //初始化
                table.rows.forEach(item => {
                    item.cells.forEach(i => {
                        i.mouseDown = false;
                    })
                });

                //记录单元格
                tTD = this;
                if (event.offsetX == tTD.offsetWidth || event.offsetX >= tTD.offsetWidth - 10) {
                    tTD.mouseDown = true;
                    tTD.oldX = event.x;
                    tTD.oldWidth = tTD.offsetWidth;
                }
            };
            table.rows[0].cells[j].onmouseup = function() {
                if (box.isDrag) {
                    return;
                }
                //结束宽度调整
                if (tTD == undefined) tTD = this;
                tTD.mouseDown = false;
                tTD.style.cursor = 'default';
            };
            table.rows[0].cells[j].onmousemove = function(event) {
                if (box.isDrag) {
                    return;
                }
                //更改鼠标样式
                if (event.offsetX > this.offsetWidth - 10) {
                    this.style.cursor = 'col-resize';
                } else {
                    this.style.cursor = 'default';
                }

                //取出暂存的Table Cell
                if (tTD == undefined) tTD = this;
                //调整宽度
                if (tTD.mouseDown != null && tTD.mouseDown == true) {
                    tTD.style.cursor = 'default';
                    if (tTD.oldWidth + (event.x - tTD.oldX) > 0)
                        tTD.width = tTD.oldWidth + (event.x - tTD.oldX);
                    //调整列宽
                    tTD.style.width = tTD.width + 'px';
                    tTD.style.cursor = 'col-resize';
                    //调整该列中的每个Cell
                    // table = tTD;
                    // while (table.tagName != 'TABLE') table = table.parentElement;
                    // for (let j = 0; j < table.rows.length; j++) {
                    //     table.rows[j].cells[tTD.cellIndex].width = tTD.width;
                    // }
                    //调整整个表
                    // table.width = tTD.tableWidth + (tTD.offsetWidth - tTD.oldWidth);
                    // table.style.width = table.width;
                }
            };
        }
    }

    simpleLineHeight() {
        var tTD; //用来存储当前更改宽度的Table Cell,避免快速移动鼠标的问题
        var table = this.table;
        let box = this.box;

        for (let j = 1; j < table.rows.length - 1; j++) {
            table.rows[j].cells[0].onmousedown = function(event) {
                if (box.isDrag) {
                    return;
                }
                //初始化
                table.rows.forEach(item => {
                    item.cells.forEach(i => {
                        i.mouseDown = false;
                    })
                });

                //记录单元格
                tTD = this;
                if (event.offsetY > tTD.offsetHeight - 10) {
                    tTD.mouseDown = true;
                    tTD.oldY = event.y;
                    tTD.oldHeight = tTD.offsetHeight;
                }
            };
            table.rows[j].cells[0].onmouseup = function() {
                if (box.isDrag) {
                    return;
                }
                //结束宽度调整
                if (tTD == undefined) tTD = this;
                tTD.mouseDown = false;
                tTD.style.cursor = 'default';
            };
            table.rows[j].cells[0].onmousemove = function(event) {
                if (box.isDrag) {
                    return;
                }
                //更改鼠标样式
                if (event.offsetY > this.offsetHeight - 10)
                    this.style.cursor = 'row-resize';
                else
                    this.style.cursor = 'default';
                //取出暂存的Table Cell
                if (tTD == undefined) tTD = this;
                //调整宽度
                if (tTD.mouseDown != null && tTD.mouseDown == true) {
                    tTD.style.cursor = 'default';
                    if (tTD.oldHeight + (event.y - tTD.oldY) > 0)
                        tTD.height = tTD.oldHeight + (event.y - tTD.oldY);
                    //调整列宽
                    tTD.style.width = tTD.height + 'px';
                    tTD.style.cursor = 'row-resize';
                    //调整该列中的每个Cell
                }
            };
        }
    }


    //处理列宽
    colsDrag() {
        var tTD; //用来存储当前更改宽度的Table Cell,避免快速移动鼠标的问题
        var table = this.table;
        let tableReal = this.tableReal;
        let box = this.box;
        for (let j = 0; j < table.rows[0].cells.length; j++) {
            let unit = table.rows[0].cells[j];
            let nextUnit = table.rows[0].cells[j + 1];
            let allWidth = 0;
            //
            unit.addEventListener('mousedown', (event) => {
                // console.log(111);
                //记录单元格
                tTD = event.target;
                //相邻两个单元格的宽度
                allWidth = unit.offsetWidth + nextUnit.offsetWidth;
                if (event.offsetX > tTD.offsetWidth - 15) {
                    tTD.mouseDown = true;
                    tTD.oldX = event.x;
                    tTD.oldWidth = tTD.offsetWidth;
                }
                //记录Table宽度
                table = tTD;
                while (table.tagName != 'TABLE') table = table.parentElement;
                tTD.tableWidth = table.offsetWidth;
            });
            //
            unit.addEventListener('mouseup', (event) => {
                //结束宽度调整
                if (tTD == undefined) tTD = event.target;
                tTD.mouseDown = false;
                tTD.style.cursor = 'default';
                allWidth = 0;
            });
            unit.addEventListener('mousemove', (event) => {
                //更改鼠标样式
                if (event.offsetX > event.target.offsetWidth - 10)
                    event.target.style.cursor = 'col-resize';
                else
                    event.target.style.cursor = 'default';
                //取出暂存的Table Cell
                if (tTD == undefined) tTD = event.target;

                if (tTD.mouseDown != null && tTD.mouseDown === true) {
                    let changeWidth = event.x - tTD.oldX;
                    tTD.style.cursor = 'default';
                    if (tTD.oldWidth + (changeWidth) > 0) {
                        tTD.width = tTD.oldWidth + (changeWidth);
                    }
                    //调整列宽
                    tTD.style.width = tTD.width + 'px';
                    // nextUnit.style.width = (allWidth - tTD.offsetWidth) + 'px';
                    tTD.style.cursor = 'col-resize';
                    //调整该列中的每个Cell
                    table = tTD;
                    while (table.tagName != 'TABLE') table = table.parentElement;
                    for (j = 0; j < table.rows.length; j++) {
                        table.rows[j].cells[tTD.cellIndex].width = tTD.width;
                    }
                    //调整整个表
                    // table.width = tTD.tableWidth + (tTD.offsetWidth - tTD.oldWidth);
                    // table.style.width = table.width + 'px';
                    // tableReal.style.width = (box.boxObject.width - 20) + 'px';
                    // tableReal.style.height = (box.boxObject.height - 20) + 'px';
                }
            });
        }
    }

    //处理行高
    lineHeight() {
        //用来存储当前更改宽度的Table Cell,避免快速移动鼠标的问题
        var tTD;
        let tableReal = this.tableReal;
        let table = this.table;
        let box = this.box;
        for (let j = 1; j < table.rows.length; j++) {
            let unit = table.rows[j].cells[0];
            unit.addEventListener('mousedown', function(event) {
                //记录单元格
                tTD = this;
                //
                if (event.offsetX > tTD.offsetWidth - 10) {
                    tTD.mouseDown = true;
                    tTD.oldX = event.x;
                    tTD.oldWidth = tTD.offsetWidth;
                }
                //
                if (event.offsetY > tTD.offsetHeight - 10) {
                    tTD.mouseDown = true;
                    tTD.oldY = event.y;
                    tTD.oldHeight = tTD.offsetHeight;
                }

                // //记录Table高度
                // table = tTD;
                // while (table.tagName != 'TABLE') table = table.parentElement;
                // tTD.tableHeight = table.offsetHeight;
            });
            unit.addEventListener('mouseup', function(event) {
                //结束宽度调整
                if (tTD == undefined) tTD = this;
                tTD.mouseDown = false;
                tTD.style.cursor = 'default';
            });

            unit.addEventListener('mousemove', function(event) {
                //更改鼠标样式
                if (event.offsetY > this.offsetHeight - 10)
                    this.style.cursor = 'row-resize';
                else
                    this.style.cursor = 'default';
                //取出暂存的Table Cell
                if (tTD == undefined) tTD = this;
                //调整宽度
                if (tTD.mouseDown != null && tTD.mouseDown === true) {
                    tTD.style.cursor = 'default';
                    if (tTD.oldHeight + (event.y - tTD.oldY) > 0) {
                        tTD.height = tTD.oldHeight + (event.y - tTD.oldY);
                    }
                    //调整列宽
                    tTD.style.height = tTD.height + 'px';
                    tTD.style.cursor = 'row-resize';
                    //调整该列中的每个Cell
                    table = tTD;
                    // while (table.tagName != 'TABLE') table = table.parentElement;
                    // for (let j = 0; j < table.rows[0].cells.length; j++) {
                    //     table.rows[j].cells[tTD.cellIndex].height = tTD.height;
                    // }
                    // 调整整个表
                    // table.height = tTD.tableHeight + (tTD.offsetHeight - tTD.oldHeight);
                    // table.style.height = table.height + 'px';
                    // tableReal.style.width = box.boxObject.width - 5 + 'px';
                    // tableReal.style.height = box.boxObject.height - 5 + 'px';
                }
            });
        }
    }

    //交汇的单元格
    crossUnit() {
        //用来存储当前更改宽度的Table Cell,避免快速移动鼠标的问题
        var tTD;
        var table = this.table;
        let unit = table.rows[0].cells[0];
        let tableReal = this.tableReal;
        let box = this.box;

        unit.addEventListener('mousedown', function(event) {
            //记录单元格
            tTD = this;
            if (event.offsetY > tTD.offsetHeight - 10) {
                tTD.mouseDown1 = true;
                tTD.oldY = event.y;
                tTD.oldHeight = tTD.offsetHeight;
            }
            if (event.offsetX > tTD.offsetWidth - 10) {
                tTD.mouseDown2 = true;
                tTD.oldX = event.x;
                tTD.oldWidth = tTD.offsetWidth;
            }

            //记录Table高度
            table = tTD;
            // while (table.tagName != 'TABLE') table = table.parentElement;
            // tTD.tableHeight = table.offsetHeight;
        });
        unit.addEventListener('mouseup', function(event) {
            //结束宽度调整
            if (tTD == undefined) tTD = this;
            tTD.mouseDown1 = false;
            tTD.mouseDown2 = false;
            tTD.style.cursor = 'default';
        });

        unit.addEventListener('mousemove', function(event) {
            {
                //更改鼠标样式
                // console.log(event.offsetX > this.offsetWidth - 10);
                if (event.offsetX > this.offsetWidth - 10) {
                    this.style.cursor = 'col-resize';
                } else {
                    this.style.cursor = 'default';
                }
                //取出暂存的Table Cell
                if (tTD == undefined) tTD = this;
                //调整宽度
                if (tTD.mouseDown2 != null && tTD.mouseDown2 === true) {
                    tTD.style.cursor = 'default';
                    if (tTD.oldWidth + (event.x - tTD.oldX) > 0) {
                        tTD.width = tTD.oldWidth + (event.x - tTD.oldX);
                    }
                    //调整列宽
                    tTD.style.width = tTD.width + 'px';
                    tTD.style.cursor = 'col-resize';
                    //调整该列中的每个Cell
                    table = tTD;
                }
            }
            //
            {
                //更改鼠标样式
                if (event.offsetY > this.offsetHeight - 10) {
                    this.style.cursor = 'row-resize';
                } else {
                    // this.style.cursor = 'default';
                }

                //取出暂存的Table Cell
                if (tTD == undefined) tTD = this;
                //调整宽度
                if (tTD.mouseDown1 != null && tTD.mouseDown1 === true) {
                    tTD.style.cursor = 'default';
                    if (tTD.oldHeight + (event.y - tTD.oldY) > 0) {
                        tTD.height = tTD.oldHeight + (event.y - tTD.oldY);
                    }
                    //调整列宽
                    tTD.style.height = tTD.height + 'px';
                    tTD.style.cursor = 'row-resize';
                    //调整该列中的每个Cell
                    table = tTD;
                }
            }

            // tableReal.style.width = box.boxObject.width - 5 + 'px';
            // tableReal.style.height = box.boxObject.height - 5 + 'px';
        });
    }



}

export default {
    MyTable
}