<template>
        <el-row class="tools_bar">
            <!-- <i class="el-icon-back" title="返回列表页" @click="backToList" style="position:absolute;top:5px;left:15px;height:0px;font-size:28px;color:#fff;z-index:99;cursor:pointer"></i> -->
            <!-- 控件通用属性操作 -->
            <div class="tools_bar_button normal">
                <span style="display:inline-block;">控件层级：
                    <el-input-number v-model="currentBox.boxReverse.zIndex" :min="1" :max="400" size="mini" style="width:100px;"></el-input-number>
                </span>
                <span style="margin-left:15px;display:inline-block;" v-show="currentTools=='icon3' || currentTools=='icon6' ">
                    打印方向：
                    <el-button icon="icon iconfont icon-shuipingzhou " @click="boxReverse('+0','deg')" size="mini" style="width:32px;height:32px;" circle="" title="水平打印"></el-button>
                    <el-button icon="icon iconfont icon-duobianxing1kaobei5" @click="boxReverse('+90','deg')"  size="mini" style="width:32px;height:32px;" circle="" title="垂直打印"></el-button>
                </span>
                <span v-if="currentTools != 'icon7'">
                    <span style="display:inline-block;margin-left:10px;">文字处理：</span>
                    <el-button title="加粗"  class="icon iconfont icon-jiacu" @click="updateText('fontWeight')" ></el-button>
                    <el-button title="倾斜字体" class="icon iconfont icon-zitixieti" @click="updateText('fontStyle')"></el-button>
                    <!-- <el-button title="删除线" style="font-size:26px;line-height:18px;text-indent:-5px;" class="icon iconfont icon-shanchuxian1" @click="updateText('line-through','line')"></el-button> -->
                    <el-button title="下划线" class="icon iconfont icon-ziyuan" @click="updateText('underline','line')"></el-button>
                    <el-button title="文字居左" class="icon iconfont icon-juzuo" @click="updateText('left','align')"></el-button>
                    <el-button title="文字居中" class="icon iconfont icon-juzhong" @click="updateText('center','align')"></el-button>
                    <el-button title="文字居右" class="icon iconfont icon-juyou" @click="updateText('right','align')"></el-button>
                    <el-button title="转换大写" style="font-size:18px" class="icon iconfont icon-daxie" @click="updateText('uppercase','trans')"></el-button>
                    <el-button title="转换小写" style="font-size:18px" class="icon iconfont icon-xiaoxie" @click="updateText('lowercase','trans')"></el-button>
                    <el-select v-model="currentBox.textObject.fontFamily" @change="updateText('fontFamily')" placeholder="微软雅黑" size="mini" style="width:96px;margin-left:5px;">
                        <el-option
                        @click.native="updateText('fontFamily')"
                        v-for="item in fontFamily"
                        :key="item.id"
                        :label="item.name"
                        :value="item.value">
                        </el-option>
                    </el-select>
                    <el-select v-model="currentBox.textObject.fontSizeNum" placeholder="字体大小" @change="updateText('fontSize')" size="mini" style="width:60px;margin-left:5px;">
                        <el-option
                        @click.native="updateText('fontSize')"
                        v-for="(item,key) in fontSzieArr"
                        :key="key"
                        :label="item.name"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </span>

                <span v-if="currentTools == 'icon7'">
                    <span style="display:inline-block;margin-left:10px;">文字处理：</span>
                    <el-button title="加粗"  class="icon iconfont icon-jiacu" @click="tableUpdateText('fontWeight')" ></el-button>
                    <el-button title="倾斜字体" class="icon iconfont icon-zitixieti" @click="tableUpdateText('fontStyle')"></el-button>
                    <!-- <el-button title="删除线" style="font-size:26px;line-height:18px;text-indent:-5px;" class="icon iconfont icon-shanchuxian1" @click="tableUpdateText('line-through','line')"></el-button> -->
                    <el-button title="下划线" class="icon iconfont icon-ziyuan" @click="tableUpdateText('underline','line')"></el-button>
                    <el-button title="文字居左" class="icon iconfont icon-juzuo" @click="tableUpdateText('left','align')"></el-button>
                    <el-button title="文字居中" class="icon iconfont icon-juzhong" @click="tableUpdateText('center','align')"></el-button>
                    <el-button title="文字居右" class="icon iconfont icon-juyou" @click="tableUpdateText('right','align')"></el-button>
                    <!-- <el-button title="转换大写" style="font-size:18px" class="icon iconfont icon-daxie" @click="tableUpdateText('uppercase','trans')"></el-button> -->
                    <!-- <el-button title="转换小写" style="font-size:18px" class="icon iconfont icon-xiaoxie" @click="tableUpdateText('lowercase','trans')"></el-button> -->
                    <el-select v-model="currentBox.textObject.fontFamily" @change="tableUpdateText('fontFamily')" placeholder="微软雅黑" size="mini" style="width:106px;margin-left:10px;">
                        <el-option
                        v-for="item in fontFamily"
                         @click.native="tableUpdateText('fontFamily')"
                        :key="item.id"
                        :label="item.name"
                        :value="item.value">
                        </el-option>
                    </el-select>
                    <el-select v-model="currentBox.textObject.fontSizeNum" placeholder="字体大小" @change="tableUpdateText('fontSize')" size="mini" style="width:100px;margin-left:10px;">
                        <el-option
                        v-for="(item,key) in fontSzieArr"
                        @click.native="tableUpdateText('fontSize')"
                        :key="key"
                        :label="item.name"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </span>
                
                <span style="margin-left:16px;display:inline-block;">位置微调：
                    <el-button icon="el-icon-top"  @mousedown.native="boxMicroReverse('micro_top',-1)" @mouseup.native="boxMicroReverseStop"></el-button>
                    <el-button icon="el-icon-bottom"  @mousedown.native="boxMicroReverse('micro_top',1)" @mouseup.native="boxMicroReverseStop"></el-button>
                    <el-button icon="el-icon-back" @mousedown.native="boxMicroReverse('micro_left',-1)" @mouseup.native="boxMicroReverseStop"></el-button>
                    <el-button icon="el-icon-right" @mousedown.native="boxMicroReverse('micro_left',1)" @mouseup.native="boxMicroReverseStop"></el-button>
                </span>
                <!-- <el-button title="水平翻转" @click="boxReverse('l','deg')" class="icon iconfont icon-shuipingzhou" style="margin-left:10px;"></el-button>
                <el-button title="垂直翻转" @click="boxReverse('v','deg')" class="icon iconfont icon-duobianxing1kaobei5" ></el-button> -->
            </div>
            <!-- 表格处理 -->
            <div class="tools_bar_button" >
                <span style="margin:0 5px 0 0;display:inline-block;">显示框线: 
                    <el-switch v-model="showTableBorder" @change="changeTableBorder"></el-switch>
                </span>
                <span style="margin-left:0px;display:inline-block;">
                    <el-select v-model="activeBorder" 
                    :disabled="!showTableBorder"
                    @change="tableReverse"  placeholder="边框位置" size="small" style="width:100px;">
                    <el-option v-for="item in borderList"
                    @click.native="tableReverse"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                    </el-select>&nbsp;<el-select v-model="activeBorderStyle"  
                    :disabled="!showTableBorder"
                    @change="tableReverse" placeholder="边框类型" size="small" style="width:100px;">
                    <el-option v-for="item in borderStyle"
                    @click.native="tableReverse"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                    </el-select>
                </span>
                <span style="margin-left:1px;display:inline-block;">
                    <el-input-number v-model="activeBorderWidth" 
                    :disabled="!showTableBorder"
                    @change="tableReverse" :min="1" :max="400" size="small" style="width:100px;"></el-input-number>
                </span>

                <!-- 数据源 -->
                <span style="margin-left:20px;display:inline-block;" >数据类型：
                    <el-radio-group size="mini" @change="changeUnitDataType(table.rows[table.current_rows][table.current_cols])" v-model="table.rows[table.current_rows][table.current_cols].isDynamic" >
                        <el-radio-button label="静态"></el-radio-button>
                        <el-radio-button label="动态" :disabled="textSource.length==0"></el-radio-button>
                    </el-radio-group>
                </span>
                <!-- 静态数据 -->
                <span style="margin-left:20px;display:inline-block;"  v-if="table.rows[table.current_rows][table.current_cols].isDynamic=='静态'">
                    <el-input
                    style="width:120px;"
                    type="text"
                    size="mini"
                    placeholder="请输入内容"
                    id="staticTd"
                    v-model="table.rows[table.current_rows][table.current_cols].valueShow" show-word-limit>
                    </el-input>
                </span>
                <!-- 动态数据 -->
                <div style="margin-left:20px;display:inline-block;" v-if="table.rows[table.current_rows][table.current_cols].isDynamic=='动态'">
                    <el-cascader
                    style="width:150px;"
                    size="small"
                    v-model="table.rows[table.current_rows][table.current_cols].source"
                    :options="textSource"
                    :props="{
                        expandTrigger: 'hover',
                        emitPath:false
                    }"
                    ></el-cascader>
                </div>
            </div>
            <div @click="save" style="cursor:pointer;" class="save" >保存</div>
            <div @click="save('exit')" style="cursor:pointer;" class="save exit" >保存退出</div>
            <div @click="save('print')" style="cursor:pointer;" class="save print" >保存打印</div>
        </el-row>  
    
</template>

<script>

import EditorObject from "./objectData.js";
import EditorMethod from "./methodObject.js";

export default {
    name:'topTools',
    data(){
        return EditorObject;
    },
    methods:EditorMethod
}
</script>
<style scoped lang="less">
@import url('../../../assets/less/normal');
@import url('../less/editor');
</style>