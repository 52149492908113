export default {
    fonts: [{
            id: 1,
            name: '微软雅黑',
            value: '微软雅黑'
        },
        {
            id: 2,
            name: '宋体',
            value: '宋体'
        },
        {
            id: 3,
            name: '楷体',
            value: '楷体'
        },
        {
            id: 4,
            name: '黑体',
            value: '黑体'
        }
    ],
    fontSzieArr: [{
        name: '10',
        value: 10
    }, {
        name: '11',
        value: 11
    }, {
        name: '12',
        value: 12
    }, {
        name: '14',
        value: 14
    }, {
        name: '16',
        value: 16
    }, {
        name: '18',
        value: 18
    }, {
        name: '20',
        value: 20
    }, {
        name: '22',
        value: 22
    }, {
        name: '24',
        value: 24
    }, {
        name: '26',
        value: 26
    }, {
        name: '28',
        value: 28
    }, {
        name: '36',
        value: 36
    }, {
        name: '48',
        value: 48
    }, {
        name: '72',
        value: 72
    }]
}