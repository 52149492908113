<template>
    <div class="tools_val" :style="{height:workarea.style.height}">

        <!-- 线条 -->
        <div class="tools_paper tools_bar"   v-show="currentTools == 'icon8'">
            <el-row >
                <div style="margin:3px 0">线条</div>
                <el-select v-model="lineBox.borderStyle" @change="changeLine" placeholder="线条类型" size="small" style="width:120px;">
                    <el-option v-for="item in borderStyle" 
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
            </el-row>  
            <el-row style="margin-top:16px;">
                <div style="margin:3px 0">线条方向</div>
                <el-select v-model="lineBox.lineDirection" @change="changeLineDirection" placeholder="线条方向" size="small" style="width:120px;">
                    <el-option v-for="item in lineDirection"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
            </el-row> 
            <div style="margin-top:16px;">
                <div style="margin:3px 0"> 线条大小<i @click="showLineWidth = true"  class="el-icon-question" style="font-size:16px;cursor:pointer;"></i></div>
                <el-input-number v-model="lineBox.height" :min="1" ::max="4000" size="small" @change="changeLine"></el-input-number>
            </div>

            <el-row style="margin-top:18px;">
                <el-button @click="insertLineBox" size="small" type="primary" style="width:90%;margin-left:8px;">插入线条</el-button>
            </el-row>

        </div>
        <!-- 纸张设置 -->
        <div class="tools_paper tools_bar"  v-show="currentTools == 'icon2'">
           <div >
                <div style="margin:3px 0;font-weight:900;color:blue;">模版名称</div>
                <el-input size="small" v-model="templateName"></el-input>
            </div>
            <el-row style="margin-top:16px;" v-if="formType === 3">
                <div style="margin:3px 0;">快递公司</div>
                <el-select size="mini" v-model="currentExpress" filterable placeholder="请选择">
                    <el-option
                    v-for="(item,key) in expressList"
                    :label="item.name"
                    :value="item.id"
                    :key="key">
                    </el-option>
                </el-select>
            </el-row>
            <el-row style="margin-top:16px;">
                <div style="margin:3px 0"> 纸张类型 </div>
                <el-select v-model="paper.paperType" placeholder="纸张类型" size="small" style="width:100%;" @change="changePaperType">
                    <el-option
                    v-for="item in paperType"
                    :key="item.id"
                    :label="item.label"
                    :value="item.id">
                    </el-option>
                </el-select>
            </el-row>
            <div style="margin-top:16px;">
                <div style="margin:3px 0"> 宽 mm</div>
                <el-input-number v-model="paper.width" @change="paperChange" :min="1" :max="2000" size="small"></el-input-number>
            </div>
            <div style="margin-top:16px;">
                <div style="margin:3px 0"> 高 mm</div>
                <el-input-number v-model="paper.height" @change="paperChange" :min="1" :max="2000" size="small"></el-input-number>
            </div>
            <div style="margin-top:16px;width:150px;">
                <div style="margin:3px 0"> 是否处理边缘 <i @click="showEdge = true"  class="el-icon-question" style="font-size:16px;cursor:pointer;"></i></div>
                <el-radio-group v-model="paper.isEdge" size="small">
                    <el-radio-button label="1" style="">否</el-radio-button>
                    <el-radio-button label="2" style="">是</el-radio-button>
                </el-radio-group>
            </div>
            <div style="margin-top:16px;" v-if="paper.isEdge==2">
                <div style="margin:3px 0"> 左 mm</div>
                <el-input-number v-model="paper.leftEdge" @change="paperChange('left')" :min="0" ::max="4000" size="small"></el-input-number>
            </div>
            <div style="margin-top:16px;" v-if="paper.isEdge==2">
                <div style="margin:3px 0"> 右 mm</div>
                <el-input-number v-model="paper.rightEdge" @change="paperChange('right')" :min="0" ::max="4000" size="small"></el-input-number>
            </div>
        </div>
        <!-- 字体 -->
        <div class="tools_font tools_bar"  v-show="currentTools == 'icon3'">
            <el-row style="">
                <div style="margin:3px 0">输入文本</div>
                <el-input size="small" id="textInput" placeholder="输入文本" v-model="text.text"></el-input>
            </el-row>      
            <el-row style="margin-top:12px;">
                <div style="margin:3px 0">数据源</div>
                <el-cascader
                    style="width:150px;"
                    size="small"
                    v-model="text.source"
                    :options="textSource"
                    :props="{
                        expandTrigger: 'hover',
                        emitPath:false
                    }"
                    ></el-cascader>
            </el-row>
            <el-row style="margin-top:18px;">
                <el-button @click="insertTextbox" size="small" type="primary" style="width:90%;margin-left:15px;">插入文本框</el-button>
            </el-row>
        </div>

        <!-- 图片 -->
        <div class="tools_paper tools_bar"  v-show="currentTools == 'icon4'">
            <el-row>
                <div style="margin:3px 0"> 宽 px</div>
                <el-input-number v-model="image.boxObject.width"  :min="1" :max="50000" size="small"></el-input-number>
            </el-row>
            <el-row style="margin-top:16px;">
                <div style="margin:3px 0"> 高 px</div>
                <el-input-number v-model="image.boxObject.height"  :min="1" :max="50000" size="small"></el-input-number>
            </el-row>

            <el-row style="margin-top:12px;">
                <el-button size="small" style="width:90%;margin-left:15px;" @click="setBg()">设置为背景</el-button>
            </el-row>

            <el-row style="margin-top:12px;">
                <el-button size="small" style="width:90%;margin-left:15px;" @click="rmBg()">清除背景</el-button>
            </el-row>
                <!-- 上传图片 -->
            <el-row style="margin-top:12px;">
                <el-upload 
                    :action="fileUrl"
                    :on-preview="pickPicture"
                    :on-error="handleError"
                    :file-list="uploadImages"
                    :headers="authorizationBearer"
                    list-type="text"
                    >
                    <el-button size="small" type="primary" style="width:108px;margin-left:15px;">点击上传</el-button>
                    <!-- <div slot="tip" class="el-upload__tip">只能上传jpg/png，且不超过500kb</div> -->
                </el-upload>
            </el-row>
            <!-- <el-row style="margin-top:18px;">
                <el-button @click="insertImage" size="small" type="primary" style="width:90%;margin-left:15px;">插入图片</el-button>
            </el-row> -->
        </div>

        <!-- 发货单 -->
        <div class="tools_paper tools_bar"  v-show="currentFormItem.name === '发货单'  && currentTools === 1 && currentFormItem.active === 1">
            <el-row>
                <div style="margin:5px 0">{{currentFormItem.name}}显示的列</div>
                <el-select v-model="currentShipMentField" 
                multiple style="width:180px;position:relative;left:-15px;">
                    <el-option
                    v-for="(item,key) in shipMentField"
                    :key="key"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
            </el-row>

            <el-row style="margin-top:15px;">
                <div style="margin:5px 0">每页打印条数</div>
                <el-input-number v-model="printPageSize" 
                    @change="changePrintPageSize"
                    :min="1" :max="50000" size="small"></el-input-number>
            </el-row>
            
            <el-row style="margin-top:15px;">
                <div style="margin:5px 0">打印空行<el-switch v-model="printEmptyLine" 
                @change="changePrintEmptyLine"
                active-color="#13ce66" style="margin-left:10px;"></el-switch></div>
            </el-row>
            
            <el-row style="margin-top:15px;">
                <div style="margin:5px 0">打印框线<el-switch v-model="borderLine" 
                @change="changePrintBorderLine"
                active-color="#13ce66" style="margin-left:10px;"></el-switch></div>
            </el-row>

            <el-row style="margin-top:15px;">
                <el-button @click="insertShipment()" size="small" 
                type="primary" style="width:108px;margin-left:12px;">插入发货单</el-button>
            </el-row>
        </div>

        <div class="tools_paper tools_bar"  v-show="this.currentBox.type === 'simpleForm' || (this.currentFormItem.id >= 4 && currentFormItem.active === 1 && showFormToolsFlag)">
            <el-row>
                <div style="margin:5px 0">{{currentFormItem.name}}显示的列</div>
                <el-select v-model="form_detail_checked" 
                multiple style="width:180px;position:relative;left:-15px;">
                    <el-option
                    v-for="(item,key) in form_detail_source"
                    :key="key"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
            </el-row>
            <el-row style="margin-top:15px;">
                <div style="margin:5px 0">每页打印条数</div>
                <el-input-number v-model="printPageSize" 
                    @change="changePrintPageSize"
                    :min="1" :max="50000" size="small"></el-input-number>
            </el-row>
            
            <el-row style="margin-top:15px;">
                <div style="margin:5px 0">打印空行<el-switch v-model="printEmptyLine" 
                @change="changePrintEmptyLine"
                active-color="#13ce66" style="margin-left:10px;"></el-switch></div>
            </el-row>
            <el-row style="margin-top:15px;">
                <div style="margin:5px 0">打印框线<el-switch v-model="borderLine" 
                @change="changePrintBorderLine"
                active-color="#13ce66" style="margin-left:10px;"></el-switch></div>
            </el-row>
            <el-row style="margin-top:15px;">
                <el-button @click="insertSimpleForm()" size="small" 
                type="primary" style="width:108px;margin-left:12px;">插入{{currentFormItem.name}}</el-button>
            </el-row>
        </div>

        <div class="tools_paper tools_bar"  v-show="currentFormItem.name === '备货单' && currentFormItem.active === 1 && currentTools === 2">
            <el-row>
                <div style="margin:5px 0">{{currentFormItem.name}}显示的列</div>
                <el-select v-model="currentPrepareField" 
                multiple style="width:180px;position:relative;left:-15px;">
                    <el-option
                    v-for="(item,key) in prepareField"
                    :key="key"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
            </el-row>

            <el-row style="margin-top:15px;">
                <div style="margin:5px 0">每页打印条数</div>
                <el-input-number v-model="printPageSize" 
                    @change="changePrintPageSize"
                    :min="1" :max="50000" size="small"></el-input-number>
            </el-row>
            
            <el-row style="margin-top:15px;">
                <div style="margin:5px 0">打印空行<el-switch v-model="printEmptyLine" 
                @change="changePrintEmptyLine"
                active-color="#13ce66" style="margin-left:10px;"></el-switch></div>
            </el-row>
            <el-row style="margin-top:15px;">
                <div style="margin:5px 0">打印框线<el-switch v-model="borderLine" 
                @change="changePrintBorderLine"
                active-color="#13ce66" style="margin-left:10px;"></el-switch></div>
            </el-row>
            <el-row style="margin-top:15px;">
                <el-button @click="insertPrepare()" size="small" 
                type="primary" style="width:108px;margin-left:12px;">插入备货单</el-button>
            </el-row>
        </div>

        <!-- 二维码 -->
        <div class="tools_paper tools_bar"  v-show="currentTools == 'icon5'">
            <el-row>
                <div style="margin:3px 0"> 宽 px</div>
                <el-input-number v-model="qr.boxObject.width" :min="1" ::max="4000" size="small"></el-input-number>
            </el-row>
            <el-row style="margin-top:16px;">
                <div style="margin:3px 0"> 高 px</div>
                <el-input-number v-model="qr.boxObject.height" :min="1" ::max="4000" size="small"></el-input-number>
            </el-row>

            <el-row style="margin-top:16px;">
                <div style="margin:3px 0"> 静态二维码数据</div>
                <el-input v-model="qr.staticText" size="small"></el-input>
            </el-row> 
            <el-row style="margin-top:5px;">
                <el-button @click="insertStaticQr()" size="small" type="primary" style="width:108px;margin-left:12px;">生成二维码</el-button>
            </el-row>  

            <el-row style="margin-top:16px;width:130px;">
                    <div style="margin:3px 0">动态二维码(数据源)</div>
                    <el-cascader
                    style="width:150px;"
                    size="small"
                    placeholder="请选择来源"
                    v-model="qr.source"
                    :options="textSource"
                    :props="{
                        expandTrigger: 'hover',
                        emitPath:false
                    }"
                    ></el-cascader>

            </el-row>
            <el-row style="margin-top:5px;">
                <el-button @click="insertDynamic(false)" size="small" type="primary" style="width:108px;margin-left:12px;">插入动态二维码</el-button>
            </el-row>   
        </div>

        <!-- 条形码 -->
        <div class="tools_paper tools_bar"  v-show="currentTools == 'icon6'">
            <el-row>
                <div style="margin:3px 0"> 宽 px</div>
                <el-input-number v-model="barCode.boxObject.width" @change="paperChange" :min="1" ::max="4000" size="small"></el-input-number>
            </el-row>
            <el-row style="margin-top:16px;">
                <div style="margin:3px 0"> 高 px</div>
                <el-input-number v-model="barCode.boxObject.height" @change="paperChange" :min="1" ::max="4000" size="small"></el-input-number>
            </el-row>
            <el-row style="margin-top:16px;">
                <div style="margin:3px 0"> 静态条形码数据</div>
                <el-input v-model="barCode.staticText" size="small"></el-input>
            </el-row> 
            <el-row style="margin-top:5px;">
                <el-button @click="insertStaticBarCode()" size="small" type="primary" style="width:108px;margin-left:12px;">生成条形码</el-button>
            </el-row>  
            <el-row style="margin-top:16px;">
                <div style="margin:3px 0"> 条形码格式</div>
                    <el-select v-model="barCode.format" placeholder="请选择来源" size="small" style="width:130px;margin-left:0px;">
                        <el-option
                        v-for="(item,key) in barcodeFormat"
                        :key="key"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>                
            </el-row>            
            <el-row style="margin-top:16px;">
                    <div style="margin:3px 0">动态条形码(数据源)</div>
                    <el-cascader
                    style="width:150px;"
                    size="small"
                    placeholder="请选择来源"
                    v-model="barCode.source"
                    :options="textSource"
                    :props="{
                        expandTrigger: 'hover',
                        emitPath:false
                    }"
                    ></el-cascader>
            </el-row>
            <el-row style="margin-top:5px;">
                <el-button @click="insertbarCode(false)" size="small" type="primary" style="width:108px;margin-left:12px;">插入动态条形码</el-button>
            </el-row>   
        </div>
        <!-- 表格 -->
        <div class="tools_paper tools_bar"  v-show="currentTools == 'icon7'">

            <div style="margin-top:20px;display:inline-block;" v-if="false">
                <div style="margin:3px 0"> 本行高 px 
                </div>
                <el-input-number v-model="activeTdRowHeight" @change="tableReverseMutiple('rows')" :min="1" ::max="4000" size="small" style="width:120px;"></el-input-number>
            </div>
            <div style="margin-top:10px;display:inline-block;" v-if="false">
                <div style="margin:3px 0"> 本列宽 px
                </div>
                <el-input-number v-model="activeTdColWidth" @change="tableReverseMutiple('cols')" :min="1" ::max="4000" size="small" style="width:120px;"></el-input-number>
            </div>
            <div style="margin-top:10px;display:inline-block;display:none;">
                <div style="margin:3px 0"> 单位 </div>
                <el-select v-model="activeTdUnit" @change="tableReverseMutiple('unit')"  placeholder="单位" size="small" style="width:120px;">
                    <el-option v-for="item in tdSizeUnit"
                    @click.native="tableReverseMutiple"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
            </div>
            <el-row style="margin-top:12px;">
                <div style="margin:3px 0"> 行数 </div>
                <el-input-number v-model="table.rowNum"  :min="1" ::max="4000" size="small"></el-input-number>
            </el-row>
            <el-row style="margin-top:12px;">
                <div style="margin:3px 0"> 列数 </div>
                <el-input-number v-model="table.colNum"  :min="1" ::max="4000" size="small"></el-input-number>
            </el-row>
            <el-row style="margin-top:12px;">
                <div style="margin:3px 0">合并单元格<el-switch v-model="mergeTable" 
                active-color="#13ce66" style="margin-left:10px;"></el-switch></div>
                
            </el-row>
            <el-row style="margin-top:18px;">
                <el-button @click="insertTable" size="small" type="primary" style="width:90%;margin-left:15px;">插入表格</el-button>
            </el-row>
        </div>

    </div>  
</template>

<script>
import EditorObject from "./objectData.js";
import EditorMethod from "./methodObject.js";

export default {
    name:'rightTools',
    data(){
        return EditorObject;
    },
    methods:EditorMethod,
    computed:{
        showFormToolsFlag:function(){
            return typeof this.currentTools !== 'string' && this.currentTools>3;
        }
    }
}
</script>

<style scoped lang="less">
@import url('../../../assets/less/normal');
@import url('../less/editor');
</style>