<template>
  <div v-if="isShow">
    <div class="ruleraxisx">
    <ul class="scalex" >
        <!-- 最小单位 -->
        <li class="feetstone" v-for="(item,key) in scalex" :class="{feetstone:item%10 != 0 && item%2==0,milestone:item%10==0,hide:item%2!=0}" :key="key" :style="{left:item*paperZoom+'mm'}">
            <span v-if="item%10==0" style="position:absolute;top:-5px;left:2px;" >{{item/10}}</span>
        </li>
        <!-- 里程碑 -->
    </ul>
    </div>
    <div class="ruleraxisy" >
    <ul class="scaley">
        <li class="feetstone" v-for="(item,key) in scaley" :class="{feetstone:item%10 != 0 && item%2==0,milestone:item%10==0,hide:item%2!=0}" :key="key" :style="{top:item*paperZoom+'mm'}">
            <span v-if="item%10==0" style="position:absolute;top:2px;left:-5px;" >{{item/10}}</span>
        </li>
    </ul>    
  </div>

  </div>
</template>

<script>

export default {
    name:'MyRuler',
    props:{
        isShow:{
            type:Boolean,
            default:false
        },
        paperZoom:{
            type:Number,
            default:1
        },
        scaley:{
            type:Array,
            default:[]
        },
        scalex:{
            type:Array,
            default:[]
        },
        x:{
            type:Number,
            default:286
        },
        y:{
            type:Number,
            default:206
        },        
        
    },
    data(){
        return {}
    },
    methods:{
        initData(){
            for(var i=0;i<parseInt(this.x/this.paperZoom);i++){
                this.scalex.push(i);
            }

            for(var i=0;i<parseInt(this.y/this.paperZoom);i++){
                this.scaley.push(i);
            }
        }
    },
    mounted(){
        this.initData();
    }
}
</script>

<style scoped lang="less">
@import url('../less/ruler');
</style>