<template>
        <el-row class="tools_bar" style="height:50px;margin-bottom:15px;position:relative;top:-10px;">
            <div class="tools_bar_button" v-if="currentTools == 'icon7'">
                <!-- 数据源 -->
                <span style="margin-left:20px;display:inline-block;">数据类型：
                    <el-radio-group size="mini" v-model="table.rows[table.current_rows][table.current_cols].isDynamic">
                        <el-radio-button label="静态" ></el-radio-button>
                        <el-radio-button label="动态"></el-radio-button>
                    </el-radio-group>
                </span>
                <!-- 静态数据 -->
                <span style="margin-left:20px;display:inline-block;" v-if="table.rows[table.current_rows][table.current_cols].isDynamic=='静态'">请输入文字：
                    <el-input
                    style="width:120px;"
                    type="text"
                    size="mini"
                    placeholder="请输入内容"
                    v-model="table.rows[table.current_rows][table.current_cols].valueShow"
                    show-word-limit>
                    </el-input>
                </span>
                <!-- 动态数据 -->
                <div style="margin-left:20px;display:inline-block;" v-if="table.rows[table.current_rows][table.current_cols].isDynamic=='动态'">选择动态数据:
                    <el-select v-model="table.rows[table.current_rows][table.current_cols].valueShow" size="mini" placeholder="请选择来源" style="width:129px;">
                    <el-option
                    v-for="(item,key) in textSource"
                    :key="key"
                    @change="setTdField"
                    :label="item.name"
                    :value="item.uuid">
                    </el-option>
                </el-select></div>
                <!-- 合并单元格 -->
                <div style="margin-left:20px;display:inline-block;" >合并单元格：
                    <el-button size="small" @click="mergeTable=true">去合并</el-button>
                </div>
            </div>
        </el-row> 
    
</template>

<script>

import EditorObject from "./objectData.js";
import EditorMethod from "./methodObject.js";

export default {
    name:'topTools',
    data(){
        return EditorObject;
    },
    methods:EditorMethod,
    mounted(){
        // console.log(this.borderType);
    }
}
</script>
<style scoped lang="less">
@import url('../../../assets/less/normal');
@import url('../less/editor');
</style>