<template>
    <div>
    
    </div>
</template>

<script>
import EditorObject from "./objectData.js";
import EditorMethod from "./methodObject.js";

export default {
    name:'topTools',
    data(){
        return EditorObject;
    },
    methods:EditorMethod
}
</script>

<style>

</style>